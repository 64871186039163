<script>
    import {Container} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";
    import { activePortal } from "../../Shared/Data/Portal/portalStore";
    import Upload from "../../Shared/Components/Upload/Upload.svelte";
    import {
        checkCaseForNextStep
    } from "../../Shared/Data/Tool/toolStore";

    function incomeUploaded(e) {
        checkCaseForNextStep();
    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Upload proof of income for your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

<Container fluid class="proceed d-flex position-relative">
    <Container class="py-2 mb-4">
        <h2 class="my-3 mb-4">Proof of Income</h2>
        <p>Please upload a proof of income document. This could be a recent payslip or bank statement, or SA302 document if you are self employed.</p>
        <Upload class="upload" uploadType='proof-of-income' on:uploaded={incomeUploaded} />
    </Container>
</Container>

<Footer/>

<style>
</style>
