<script>
    import {Container, Spinner} from "sveltestrap";
</script>

<Container class="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
    <h1>Loading...</h1>
    <p>One moment</p>
    <Spinner type="grow" color="primary" />
</Container>

<style>
</style>