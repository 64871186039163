<script>
    import {activePortal} from "../../Shared/Data/Portal/portalStore";
    import {Button, Icon, Spinner} from "sveltestrap";
    import {setContext} from "svelte";
    import {router} from "tinro";

    export let accessCode = "";
    setContext('accessCode', accessCode);

    let working = false;
    $: getStartedUrl = location('get-started');

    function location(slug) {
        return `/${$activePortal.slug.long}/${slug}/${accessCode}`;
    }

    async function getStarted() {
        router.goto(getStartedUrl);
    }

</script>

<h2>Our tool makes it easier than ever to compare { $activePortal.term.toLowerCase() } deals</h2>
<h5>When you’re looking for a { $activePortal.term.toLowerCase() }, we Believe it should be quick and simple to get a quote.</h5>
<p>Just enter a few details and get your { $activePortal.term.toLowerCase() } quotes fast – then you can compare competitive rates at your convenience.</p>
<div class="d-flex align-items-center">
    <Button disabled={working} color="success" size="lg" on:click={getStarted}>Get Started<Icon name="arrow-right-circle-fill" class="ms-2" /></Button>
    {#if working}
        <Spinner class="ms-2" color="success" />
    {/if}
</div>
