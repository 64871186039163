export const sourcingParametersModel = {
    lookingFor: null,
    propertyValue: null,
    mortgageOutstanding: null,
    mortgageTermRemaining: {
        years: null,
        months: 0
    },
    mortgageFixed: false,
    mortgageFixedTermRemaining: {
        years: null,
        months: 0
    },
    mortgageLender: "",
    raise: null,
    raiseAmount: null,
    employmentStatus: "employed",
    grossAnnualIncome: null
};