import {DateTime} from "luxon";

let minCallbackDate = new Date();
if (minCallbackDate < new Date(2024, 0, 2)) {
    minCallbackDate = new Date(2024, 0, 2);
}

export const callbackTimes = (date) => {
    if (!date) return [];

    date = new DateTime.fromISO(date);

    let current = date.toJSDate();

    let startHour = 9;
    let startMinutes = 0;
    let endHour = date.weekday === 5 ? 13 : 18;

    let today = new Date();
    let isTodaysDate =
        current.getDate() === today.getDate() &&
        current.getMonth() === today.getMonth() &&
        current.getFullYear() === today.getFullYear();

    if (isTodaysDate) {
        startHour = today.getHours();
        startMinutes = Math.ceil(today.getMinutes() / 15) * 15;
    }

    let times = [];
    for (let time = startHour; time <= endHour; time++) {
        for (let minute = (startMinutes > 0 && time === startHour ? startMinutes : 0); minute < 60; minute += 15) {

            // if is today and time is less than current time skip
            if (isTodaysDate && time < today.getHours()) continue;

            // if monday to thursday and time is 18:45 skip
            if (date.weekday < 5 && time === endHour && minute === 45) continue;

            // if monday to thursday and time is 09:00 or 09:15 skip
            if (date.weekday < 5 && time === startHour && (minute === 0 || minute === 15)) continue;

            let m = minute < 10 ? `0${minute}` : `${minute}`;
            times.push({value: `${time}:${m}`, label: `${time}:${m}`})
        }
    }
    return times;
}

export const callbackDays = () => {
    const now = DateTime.fromJSDate(minCallbackDate);
    const days = [];
    for (let day = 0; days.length < 7; day++) {
        const d = now.plus({days:day});
        if(d.weekday < 6) {
            const suffix = d.day === 1 || d.day === 21 || d.day === 31 ? "st" : d.day === 2 || d.day === 22 ? "nd" : d.day === 3 || d.day === 23 ? "rd" : "th";
            if (callbackTimes(d).length > 0) {
                days.push({
                    value: d.toString(),
                    label: `${d.weekdayLong} ${d.day}${suffix} ${d.monthLong}`,
                    dayOfWeek: d.weekday
                })
            }
        }
    }
    return days;
}