<script>
    import {Input, Label} from "sveltestrap";
    import {
        sourcingResults,
        selectedProductType
    } from "../../Data/Tool/toolStore";

    $: containsProductTransfer = $sourcingResults.find(x => x.quoteType === 'productTransfer') !== undefined;
    $: containsCustomerRequest = $sourcingResults.find(x => x.quoteType === 'customerRequest') !== undefined;

    $: paymentFrom = $sourcingResults.reduce((result, x) => {
        result[x.quoteType] = x.products.length > 0 ? x.products[0].rates.length > 0 ? x.products[0].rates[0].monthlyRepayment : "N/A" : "N.A";
        return result;
    }, {});

</script>

<div class="d-flex flex-column flex-lg-row w-100 align-items-stretch mb-3" style="gap:1rem;">
    {#if containsProductTransfer}
        <div class={`w-100 d-flex flex-column ${$selectedProductType === 'productTransfer' ? 'text-black shadow' : 'opacity-75 shadow-sm' }`}>
            <div class="bg-primary text-white p-2">
                <Input
                        class="mb-0 py-0"
                        bsSize="lg"
                        type="radio"
                        name="productTransfer"
                        id="productTransfer"
                        value="productTransfer"
                        bind:group={$selectedProductType}
                        label="I want to stay with my current lender"
                />
            </div>
            <Label for="productTransfer" class="p-2">
                <h5>Payments from &pound;{
                    paymentFrom['productTransfer'] ? paymentFrom['productTransfer'].toLocaleString("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }) : "N/A"
                } per month</h5>
                <ul class="mb-0">
                    <li>No additional borrowing & your term remains the same.</li>
                    <li>A quicker, easier process due to typically less lender checks.</li>
                </ul>
            </Label>
        </div>
    {/if}
    {#if containsCustomerRequest}
        <div class={`w-100 d-flex flex-column ${$selectedProductType === 'customerRequest' ? 'text-black shadow' : 'opacity-75 shadow-sm' }`}>
            <div class="bg-primary text-white p-2">
                <Input
                        class="mb-0 py-0"
                        bsSize="lg"
                        type="radio"
                        name="customerRequest"
                        id="customerRequest"
                        value="customerRequest"
                        bind:group={$selectedProductType}
                        label="I want to see deals from all lenders"
                />
            </div>
            <Label for="customerRequest" class="p-2">
                <h5>Payments from &pound;{
                    paymentFrom['customerRequest'] ? paymentFrom['customerRequest'].toLocaleString("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }) : "N/A"
                } per month</h5>
                <ul class="mb-0">
                    <li>Ability to borrow more &amp; change your mortgage term.</li>
                    <li>View all mortgage details in the market.</li>
                </ul>
            </Label>
        </div>
    {/if}
</div>
