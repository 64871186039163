<script>
    import {activePortal, setActivePortal} from "./Shared/Data/Portal/portalStore";
    import {onMount, setContext} from "svelte";
    import {router} from "tinro";
    import {
        loadCaseDetails,
        accessCode as currentAccessCode,
        clearSession,
        appFormResult, checkCaseForNextStep, gotoLocation
    } from "./Shared/Data/Tool/toolStore";
    import Loading from "./Views/Loading/Loading.svelte";

    export let accessCode = null;
    export let portal = null;
    export let tokenRequired = false;

    let loadingCaseDetails = false;

    // Set context for children (use getContext on children to get values)
    setContext('accessCode', accessCode);
    setContext('portal', portal);

    // Set Active Portal
    setActivePortal(portal);

    // Current route
    let currentRoute = null;

    onMount(async () => {
        // if no portal, redirect to home to select
        if (!$activePortal.slug) {
            router.goto("/");
        }
        // keep track of route for page loads
        router.subscribe((x) => {
            currentRoute = x;
        });
        // if incoming access code is different to current access code, clear session
        if (accessCode !== null && $currentAccessCode !== null && (accessCode !== $currentAccessCode)) {
            $currentAccessCode = null;
            clearSession();
        }
        // if access code is set and current access code is null, load case details
        if (accessCode && accessCode.length && $currentAccessCode === null) {
            loadingCaseDetails = true;
            await loadCaseDetails(accessCode);
            loadingCaseDetails = false;
        }
        // if from route is null then its a page load, check for case progress
        if (!currentRoute.from && $appFormResult && $appFormResult.journey && $appFormResult.journey.journey) {
            checkCaseForNextStep();
        }
        // if no results or token then redirect to proceed
        if (tokenRequired && (!$appFormResult || !$appFormResult.token)) {
            gotoLocation('proceed');
        }
    });
</script>

{#if $activePortal.slug && loadingCaseDetails === false}
    <slot></slot>
{/if}

{#if loadingCaseDetails}
    <Loading/>
{/if}
