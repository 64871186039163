
export default [
    {value: "", label: "Not Applicable"},
    {value: "Accord Mortgages", label: "Accord Mortgages"},
    {value: "Adam & Company plc", label: "Adam & Company plc"},
    {value: "Advantage", label: "Advantage"},
    {value: "Affirmative Finance Ltd", label: "Affirmative Finance Ltd"},
    {value: "Ahli United Bank (UK) plc", label: "Ahli United Bank (UK) plc"},
    {value: "AIB Group (UK) plc", label: "AIB Group (UK) plc"},
    {value: "Airdrie Savings Bank", label: "Airdrie Savings Bank"},
    {value: "Aldermore Bank plc", label: "Aldermore Bank plc"},
    {value: "Alliance & Leicester plc", label: "Alliance & Leicester plc"},
    {value: "Allied Irish Bank (GB)", label: "Allied Irish Bank (GB)"},
    {value: "Amber Homeloans Ltd", label: "Amber Homeloans Ltd"},
    {value: "Arbuthnot Latham", label: "Arbuthnot Latham"},
    {value: "Atom Bank", label: "Atom Bank"},
    {value: "Aviva Equity Release UK Ltd", label: "Aviva Equity Release UK Ltd"},
    {value: "Bank of China", label: "Bank of China"},
    {value: "Bank of Cyprus UK", label: "Bank of Cyprus UK"},
    {value: "Bank of Ireland", label: "Bank of Ireland"},
    {value: "Bank of Scotland plc", label: "Bank of Scotland plc"},
    {value: "Barclays Bank plc", label: "Barclays Bank plc"},
    {value: "Barnsley Building Society", label: "Barnsley Building Society"},
    {value: "Basinghall Finance plc", label: "Basinghall Finance plc"},
    {value: "Bath Building Society", label: "Bath Building Society"},
    {value: "Beverley Mortgages", label: "Beverley Mortgages"},
    {value: "Birmingham Midshires", label: "Birmingham Midshires"},
    {value: "Bluestone Mortgages", label: "Bluestone Mortgages"},
    {value: "BM Solutions Mortgages", label: "BM Solutions Mortgages"},
    {value: "Bradford & Bingley plc", label: "Bradford & Bingley plc"},
    {value: "Bristol & West plc", label: "Bristol & West plc"},
    {value: "Britannia Building Society", label: "Britannia Building Society"},
    {value: "Britannic Money plc", label: "Britannic Money plc"},
    {value: "Buckinghamshire Building Society", label: "Buckinghamshire Building Society"},
    {value: "Cambridge Building Society", label: "Cambridge Building Society"},
    {value: "Capital Home Loans", label: "Capital Home Loans"},
    {value: "Castle Trust", label: "Castle Trust"},
    {value: "Central Homeloans Ltd", label: "Central Homeloans Ltd"},
    {value: "Charter Court Financial Services Ltd", label: "Charter Court Financial Services Ltd"},
    {value: "Chelsea Building Society", label: "Chelsea Building Society"},
    {value: "Cheltenham & Gloucester plc", label: "Cheltenham & Gloucester plc"},
    {value: "Cheshire Building Society", label: "Cheshire Building Society"},
    {value: "CHL Mortgages", label: "CHL Mortgages"},
    {value: "Chorley & District Building Society", label: "Chorley & District Building Society"},
    {value: "Church House Trust", label: "Church House Trust"},
    {value: "CIS Mortgage Maker Ltd", label: "CIS Mortgage Maker Ltd"},
    {value: "Clydesdale Bank plc", label: "Clydesdale Bank plc"},
    {value: "Commercial First", label: "Commercial First"},
    {value: "Counties Home Loan Management", label: "Counties Home Loan Management"},
    {value: "Coutts & Co", label: "Coutts & Co"},
    {value: "Coventry Building Society", label: "Coventry Building Society"},
    {value: "Crocus Home Loans Ltd", label: "Crocus Home Loans Ltd"},
    {value: "Cumberland Mortgages", label: "Cumberland Mortgages"},
    {value: "Danske Bank", label: "Danske Bank"},
    {value: "Darlington Building Society", label: "Darlington Building Society"},
    {value: "DB UK Bank Ltd", label: "DB UK Bank Ltd"},
    {value: "Derbyshire Building Society", label: "Derbyshire Building Society"},
    {value: "Derbyshire Home Loans Ltd", label: "Derbyshire Home Loans Ltd"},
    {value: "Direct Line Financial Services Ltd", label: "Direct Line Financial Services Ltd"},
    {value: "Dudley Building Society", label: "Dudley Building Society"},
    {value: "Dunfermline Building Society", label: "Dunfermline Building Society"},
    {value: "Earl Shilton Mortgages", label: "Earl Shilton Mortgages"},
    {value: "Ecology Building Society", label: "Ecology Building Society"},
    {value: "Edeus", label: "Edeus"},
    {value: "Egg Banking plc", label: "Egg Banking plc"},
    {value: "E-Mex Home Funding Ltd", label: "E-Mex Home Funding Ltd"},
    {value: "Engage Credit Limited", label: "Engage Credit Limited"},
    {value: "First Active Mortgages", label: "First Active Mortgages"},
    {value: "First Commercial Bank Ltd ", label: "First Commercial Bank Ltd "},
    {value: "First Direct Bank", label: "First Direct Bank"},
    {value: "First National Home Finance", label: "First National Home Finance"},
    {value: "First Trust Bank", label: "First Trust Bank"},
    {value: "Furness Building Society", label: "Furness Building Society"},
    {value: "Future Mortgages", label: "Future Mortgages"},
    {value: "GE Money Home Lending Ltd", label: "GE Money Home Lending Ltd"},
    {value: "GMAC", label: "GMAC"},
    {value: "Godiva Mortgages Ltd", label: "Godiva Mortgages Ltd"},
    {value: "Halifax plc", label: "Halifax plc"},
    {value: "Hampshire Trust plc", label: "Hampshire Trust plc"},
    {value: "Handelsbanken", label: "Handelsbanken"},
    {value: "Hanley Economic Building Society", label: "Hanley Economic Building Society"},
    {value: "Harpenden Building Society", label: "Harpenden Building Society"},
    {value: "HBOS plc", label: "HBOS plc"},
    {value: "Hinckley and Rugby Building Society", label: "Hinckley and Rugby Building Society"},
    {value: "Hodge Lifetime", label: "Hodge Lifetime"},
    {value: "Holmesdale Building Society", label: "Holmesdale Building Society"},
    {value: "HSBC Bank plc", label: "HSBC Bank plc"},
    {value: "I Group Mortgages Ltd", label: "I Group Mortgages Ltd"},
    {value: "ING Direct N.V.", label: "ING Direct N.V."},
    {value: "Intelligent Finance", label: "Intelligent Finance"},
    {value: "Ipswich Building Society", label: "Ipswich Building Society"},
    {value: "ITL Mortgages Ltd", label: "ITL Mortgages Ltd"},
    {value: "JPMorgan Chase Bank, N.A.", label: "JPMorgan Chase Bank, N.A."},
    {value: "Just Retirement Ltd", label: "Just Retirement Ltd"},
    {value: "Kensington Mortgages", label: "Kensington Mortgages"},
    {value: "Kent Reliance", label: "Kent Reliance"},
    {value: "Landmark Mortgages Limited", label: "Landmark Mortgages Limited"},
    {value: "Leeds and Holbeck plc", label: "Leeds and Holbeck plc"},
    {value: "Leeds Building Society", label: "Leeds Building Society"},
    {value: "Leek United Building Society", label: "Leek United Building Society"},
    {value: "Legal & General Mortgage Club", label: "Legal & General Mortgage Club"},
    {value: "Lloyds Bank plc", label: "Lloyds Bank plc"},
    {value: "Lloyds TSB Mortgages", label: "Lloyds TSB Mortgages"},
    {value: "Lloyds TSB Scotland plc", label: "Lloyds TSB Scotland plc"},
    {value: "London Scottish Bank plc", label: "London Scottish Bank plc"},
    {value: "Loughborough Mortgages", label: "Loughborough Mortgages"},
    {value: "LV Equity Release Limited", label: "LV Equity Release Limited"},
    {value: "Manchester Building Society", label: "Manchester Building Society"},
    {value: "Mansfield Building Society", label: "Mansfield Building Society"},
    {value: "Market Harborough Building Society", label: "Market Harborough Building Society"},
    {value: "Market Harborough Mortgages Ltd", label: "Market Harborough Mortgages Ltd"},
    {value: "Mars Capital Finance Ltd", label: "Mars Capital Finance Ltd"},
    {value: "Marsden Building Society", label: "Marsden Building Society"},
    {value: "Masthaven", label: "Masthaven"},
    {value: "MBS Lending Ltd", label: "MBS Lending Ltd"},
    {value: "Melton Mowbray Building Society", label: "Melton Mowbray Building Society"},
    {value: "Metro Bank plc", label: "Metro Bank plc"},
    {value: "Monmouthshire Building Society", label: "Monmouthshire Building Society"},
    {value: "More 2 Life", label: "More 2 Life"},
    {value: "Morgan Stanley Bank Ltd", label: "Morgan Stanley Bank Ltd"},
    {value: "Mortgage Agency Services Number Five", label: "Mortgage Agency Services Number Five"},
    {value: "Mortgage Agency Services Number Four", label: "Mortgage Agency Services Number Four"},
    {value: "Mortgage Agency Services Number One", label: "Mortgage Agency Services Number One"},
    {value: "Mortgage Agency Services Number Six", label: "Mortgage Agency Services Number Six"},
    {value: "Mortgage Agency Services Number Three", label: "Mortgage Agency Services Number Three"},
    {value: "Mortgage Agency Services Number Two", label: "Mortgage Agency Services Number Two"},
    {value: "Mortgage Express Ltd", label: "Mortgage Express Ltd"},
    {value: "Mortgage Trust plc", label: "Mortgage Trust plc"},
    {value: "Mortgages One Ltd", label: "Mortgages One Ltd"},
    {value: "Mortgages plc", label: "Mortgages plc"},
    {value: "National Counties Building Society", label: "National Counties Building Society"},
    {value: "Nationwide Building Society", label: "Nationwide Building Society"},
    {value: "NatWest Bank", label: "NatWest Bank"},
    {value: "Nedbank Private Wealth Limited", label: "Nedbank Private Wealth Limited"},
    {value: "New Life Mortgages Ltd", label: "New Life Mortgages Ltd"},
    {value: "Newbury Building Society", label: "Newbury Building Society"},
    {value: "Newcastle Building Society", label: "Newcastle Building Society"},
    {value: "No1 CopperPot Credit Union", label: "No1 CopperPot Credit Union"},
    {value: "North Yorkshire Mortgages Ltd", label: "North Yorkshire Mortgages Ltd"},
    {value: "Northern Rock Asset Management plc (NRAM)", label: "Northern Rock Asset Management plc (NRAM)"},
    {value: "Norwich & Peterborough Building Society", label: "Norwich & Peterborough Building Society"},
    {value: "Nottingham Building Society", label: "Nottingham Building Society"},
    {value: "Oakwood Homeloans Ltd", label: "Oakwood Homeloans Ltd"},
    {value: "One Savings Bank plc", label: "One Savings Bank plc"},
    {value: "Paratus", label: "Paratus"},
    {value: "Penrith Building Society", label: "Penrith Building Society"},
    {value: "Pepper Mortgages", label: "Pepper Mortgages"},
    {value: "Pink Home Loans", label: "Pink Home Loans"},
    {value: "Platform Funding Ltd", label: "Platform Funding Ltd"},
    {value: "PMS", label: "PMS"},
    {value: "Post Office", label: "Post Office"},
    {value: "Precise Mortgages", label: "Precise Mortgages"},
    {value: "Preferred Mortgages Ltd", label: "Preferred Mortgages Ltd"},
    {value: "Principality Building Society", label: "Principality Building Society"},
    {value: "Progressive Building Society", label: "Progressive Building Society"},
    {value: "Prudential Banking plc", label: "Prudential Banking plc"},
    {value: "Redstone Mortgages Ltd", label: "Redstone Mortgages Ltd"},
    {value: "Rothschild", label: "Rothschild"},
    {value: "Royal Bank of Scotland plc", label: "Royal Bank of Scotland plc"},
    {value: "Saffron Building Society", label: "Saffron Building Society"},
    {value: "Santander UK plc", label: "Santander UK plc"},
    {value: "Scottish Building Society", label: "Scottish Building Society"},
    {value: "Scottish Widows Bank plc", label: "Scottish Widows Bank plc"},
    {value: "Shawbrook Bank Ltd", label: "Shawbrook Bank Ltd"},
    {value: "Shepshed Building Society", label: "Shepshed Building Society"},
    {value: "Skipton Building Society", label: "Skipton Building Society"},
    {value: "Southern Pacific Mortgage Lending", label: "Southern Pacific Mortgage Lending"},
    {value: "St James's Place Bank", label: "St James's Place Bank"},
    {value: "Stafford Railway Building Society", label: "Stafford Railway Building Society"},
    {value: "Standard Life Assurance Company", label: "Standard Life Assurance Company"},
    {value: "Standard Life Bank Ltd", label: "Standard Life Bank Ltd"},
    {value: "State Bank of India UK", label: "State Bank of India UK"},
    {value: "Stonehaven UK Ltd", label: "Stonehaven UK Ltd"},
    {value: "Svenska Handelsbanken Ab plc", label: "Svenska Handelsbanken Ab plc"},
    {value: "Swansea Building Society", label: "Swansea Building Society"},
    {value: "Teachers Building Society", label: "Teachers Building Society"},
    {value: "Tesco Personal Finance plc", label: "Tesco Personal Finance plc"},
    {value: "The Bank of Asia Ltd", label: "The Bank of Asia Ltd"},
    {value: "The Co-operative Bank plc", label: "The Co-operative Bank plc"},
    {value: "The Mortgage Business", label: "The Mortgage Business"},
    {value: "The Mortgage Works", label: "The Mortgage Works"},
    {value: "The One Account Ltd", label: "The One Account Ltd"},
    {value: "The Woolwich", label: "The Woolwich"},
    {value: "Tipton & Coseley Building Society", label: "Tipton & Coseley Building Society"},
    {value: "Tiuta plc", label: "Tiuta plc"},
    {value: "TSB", label: "TSB"},
    {value: "UBS Ltd", label: "UBS Ltd"},
    {value: "UCB Home Loans Corporation Ltd", label: "UCB Home Loans Corporation Ltd"},
    {value: "Ulster Bank Ltd", label: "Ulster Bank Ltd"},
    {value: "Vernon Building Society", label: "Vernon Building Society"},
    {value: "Verso Limited", label: "Verso Limited"},
    {value: "Virgin Money plc", label: "Virgin Money plc"},
    {value: "Wave Lending", label: "Wave Lending"},
    {value: "West Bromwich Building Society", label: "West Bromwich Building Society"},
    {value: "Yorkshire Bank", label: "Yorkshire Bank"},
    {value: "Yorkshire Bank Homeloans Ltd", label: "Yorkshire Bank Homeloans Ltd"},
    {value: "Yorkshire Building Society", label: "Yorkshire Building Society"},
]