<script>
    import {onMount, tick} from "svelte";

    let loaded = false
    let trustBox = null;
    let trustBoxScript = null;

    export let templateId = "";
    export let businessUnitId = "";
    export let domain = "";

    export let height = "130px";
    export let width = "100%";
    export let theme = "light";
    export let stars = "5";
    export let cssClass = "";

    $: {
        // required so any changes force
        // the trust box to get rebuilt
        templateId = templateId;
        businessUnitId = businessUnitId;
        domain = domain;
        cssClass = cssClass;
        // make sure it was initially mounted otherwise
        // holding div wont be rendered in time
        if (loaded) {
            buildTrustBox();
        }
    }

    onMount(async () => {
        await buildTrustBox();
        loaded = true;
    });

    async function buildTrustBox() {
        await generateTrustBox();
        await initializeTrustBox()
    }

    async function loadTrustBox() {
        await tick();
        if (window.Trustpilot && trustBox) {
            window.Trustpilot.loadFromElement(trustBox);
        }
    }

    async function generateTrustBox() {
        if (trustBox != null) {
            trustBox.remove();
        }
        trustBox = document.createElement("div");
        trustBox.setAttribute("class", `reviews container ${cssClass}`);
        trustBox.setAttribute("data-locale", "en-GB");
        trustBox.setAttribute("data-template-id", templateId);
        trustBox.setAttribute("data-businessunit-id", businessUnitId);
        trustBox.setAttribute("data-style-height", height);
        trustBox.setAttribute("data-style-width", width);
        trustBox.setAttribute("data-theme", theme);
        trustBox.setAttribute("data-stars", stars);

        let link = document.createElement("a");
        link.setAttribute("href", `https://uk.trustpilot.com/review/${ domain }`);
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener");
        link.setAttribute("tinro-ignore", "");
        link.innerText = "Trust Pilot";

        trustBox.appendChild(link);

        let holder = document.getElementById('trustpilotholder');
        holder.appendChild(trustBox);
    }

    function initializeTrustBox() {
        if (trustBoxScript != null) {
            trustBoxScript.remove();
        }
        trustBoxScript = document.createElement("script");
        trustBoxScript.addEventListener("load", loadTrustBox);
        trustBoxScript.setAttribute("data-businessUnitId", `${businessUnitId}`);
        trustBoxScript.async = true;
        trustBoxScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        document.body.appendChild(trustBoxScript);
    }

</script>

<div id="trustpilotholder" class="trust-pilot {cssClass}">
    <h3 class="pb-3">We Believe... Do you? Review our Trustpilot reviews:</h3>
</div>

<style>
    .trust-pilot {
        padding: 1em 1em 1.5em 1em;
        text-align: center;
    }
</style>