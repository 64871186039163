<script>
    import {createEventDispatcher} from 'svelte';
    import {Card, CardBody, Icon} from "sveltestrap";

    const dispatch = createEventDispatcher();
    export let closable = false;
    export let icon = true;

    function remove() {
        dispatch("remove");
    }
</script>

<Card class="file bg-light mb-2">
    <CardBody class="d-flex align-items-center">
        <slot name="icon">
            {#if icon}
                <Icon name="file-earmark-text fs-2 me-2"/>
            {/if}
        </slot>
        <div class="file__info">
            <slot></slot>
            {#if closable}
                <button type="button" class="btn-close position-absolute top-0 end-0 m-2" aria-label="Close" on:click={remove}></button>
            {/if}
        </div>
    </CardBody>
</Card>

<style>
    .file__info {
        margin-right:1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
