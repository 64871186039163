<script>
    import {createEventDispatcher} from "svelte";
    const dispatch = createEventDispatcher();

    import { isLoan } from "../../Data/Portal/portalStore";

    import {Row, Col, FormGroup, Input, InputGroupText, InputGroup, Button, Icon} from "sveltestrap";
    import {sourcingParameters, source} from "../../Data/Tool/toolStore";

    $: isRaise = $sourcingParameters.raise === true
    $: isPurchase = $sourcingParameters.lookingFor === "purchase"

    function update() {
        dispatch('update');
        source();
    }

    function info() {
        dispatch('info');
    }

</script>

<div class="`w-100 shadow-sm mb-3">
    <div class="bg-light p-2 d-flex align-items-center">
        <span class="fw-bold">Quote Criteria</span>
    </div>
    <div class="p-2">
        <Row class="g-2">
            <Col xs="12">
                <InputGroup>
                    <InputGroupText>£</InputGroupText>
                    <FormGroup floating label="Property Value" class="mb-0">
                        <Input
                                type="number"
                                name="propertyValue"
                                id="propertyValue"
                                bind:value={$sourcingParameters.propertyValue}
                        />
                    </FormGroup>
                </InputGroup>
            </Col>
            {#if isPurchase}
                <Col xs="12">
                    <InputGroup>
                        <InputGroupText>£</InputGroupText>
                        <FormGroup floating label="Borrowing" class="mb-0">
                            <Input
                                    type="number"
                                    name="borrowing"
                                    id="borrowing"
                                    bind:value={$sourcingParameters.raiseAmount}
                            />
                        </FormGroup>
                    </InputGroup>
                </Col>
            {:else}
                <Col xs="12">
                    <InputGroup>
                        <InputGroupText>£</InputGroupText>
                        <FormGroup floating label="Outstanding Mortgage" class="mb-0">
                            <Input
                                    type="number"
                                    name="chargeBalance"
                                    id="chargeBalance"
                                    bind:value={$sourcingParameters.mortgageOutstanding}
                            />
                        </FormGroup>
                    </InputGroup>
                </Col>
            {/if}
            {#if isRaise}
                <Col xs="12">
                    <InputGroup>
                        <InputGroupText>£</InputGroupText>
                        <FormGroup floating label="Additional Borrow" class="mb-0">
                            <Input
                                    type="number"
                                    name="raiseAmount"
                                    id="raiseAmount"
                                    bind:value={$sourcingParameters.raiseAmount}
                            />
                        </FormGroup>
                    </InputGroup>
                </Col>
            {/if}
            <Col xs="12" class="d-flex" style="gap:0.5rem;">
                <FormGroup floating label="Term (Years)" class="mb-0 w-100">
                    <Input
                            type="select"
                            name="mortgageTermRemainingYears"
                            id="mortgageTermRemainingYears"
                            bind:value={$sourcingParameters.mortgageTermRemaining.years}
                    >
                        <option value={null} disabled>Years</option>
                        {#each Array.from({length: 41}, (v, i) => i) as year}
                            {#if $isLoan || year >= 5}
                                <option value={year}>{year} year{year > 1 ? 's' : ''}</option>
                            {/if}
                        {/each}
                    </Input>
                </FormGroup>
                <FormGroup floating label="Term (Months)" class="mb-0 ml-2 w-100">
                    <Input
                            type="select"
                            name="mortgageTermRemainingMonths"
                            id="mortgageTermRemainingMonths"
                            bind:value={$sourcingParameters.mortgageTermRemaining.months}
                    >
                        <option value={null} disabled>Months</option>
                        {#each Array.from({length: 11}, (v, i) => i) as month}
                            <option value={month}>{month} month{month > 1 ? 's' : ''}</option>
                        {/each}
                    </Input>
                </FormGroup>
            </Col>
        </Row>
    </div>
    <div class="bg-light p-2">
        <Button
                color="primary"
                class="d-block ms-auto"
                on:click={update}
                >Search</Button>
    </div>
</div>

<style>
</style>