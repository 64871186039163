<script>

    import {onMount, setContext} from "svelte";
    import {activePortal, setActivePortal} from "../../Shared/Data/Portal/portalStore";
    import {portalList} from "../../Shared/Data/Portal/portalList";

    import Footer from "../../Shared/Layout/Footer.svelte";
    import {Button, Card, CardBody, Col, Column, Container, Image, Row} from "sveltestrap";
    import HomeContent from "./HomeContent.svelte";

    export let accessCode = "";
    setContext('accessCode', accessCode);

    onMount(() => {
       // if (!$activePortal.term) {
       //     setActivePortal('first-charge');
       // }
    });

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - ${$activePortal.term} Quote Tool`}</title>
</svelte:head>

{#if $activePortal.term }
    <Container fluid class="home position-relative p-0 m-0 w-100">
        <Image
                fluid
                class="w-100"
                src="/images/mortgage-tool.png"
                alt="Our new { $activePortal.term.toLowerCase() } quote tool"
        />
        <div class="position-absolute d-flex align-items-center" style="top:0;bottom:0;left:0;right:0;">
            <Container class="d-none d-md-block text-white">
                <HomeContent accessCode={accessCode} />
            </Container>
        </div>
    </Container>
    <Container class="d-md-none py-4 px-3">
        <HomeContent accessCode={accessCode} />
    </Container>
    <Footer/>
{:else}
    <Container fluid class="home position-relative p-0 m-0 h-100 w-100">
        <Container class="d-flex flex-column h-100 w-100">
            <p></p>
            <div class="d-flex flex-wrap align-items-center justify-content-center" style="gap:1rem;">
                {#each portalList.all as p}
                    {#if p.api !== "specialist-finance"}
                        <Card title={p.term}>
                            <CardBody>
                                <div class="d-flex flex-column">
                                <Image src="/{ p.brand.logo }"
                                       alt="{ p.brand.name }"
                                       style="max-height: 100px;"
                                       class="mb-3"
                                />
                                <Button color="secondary" class="mt-3" href="/{p.term.toLowerCase()}/welcome/">Get a {p.term}</Button>
                           </div>
                            </CardBody>
                        </Card>
                    {/if}
                {/each}
            </div>
        </Container>
    </Container>
{/if}

<style>
</style>
