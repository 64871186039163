<script>
    import { getFormattedDateForUpload } from '../../Data/Utils/utils';
    import {createEventDispatcher, getContext} from 'svelte';
    const dispatch = createEventDispatcher();

    import {uploadDocument} from "../../Data/Tool/toolStore";
    import UploadFile from "./UploadFile.svelte";
    import {Button, Col, Icon, Modal, ModalBody, ModalFooter, Row} from "sveltestrap";

    let fileSelect;
    let uploadQueue = [];
    let working = false;
    let confirm = false;

    let uploadName = "";
    export let uploadType = "default";

    function browse() {
        fileSelect.click();
    }

    const allowedTypes = new Set(["application/pdf", "image/jpeg", "image/png"]);
    let fileCounter = 0;

    function addToUploadQueue() {
        for (const file of fileSelect.files) {
            const exists = uploadQueue.some(f => f.file.name === file.name && f.file.type === file.type && f.file.size === file.size && f.file.lastModified === file.lastModified);
            if (!exists) {
                let error = null;
                if (file.size > 26214400) {
                    error = "File is larger than 25MB";
                } else if (!allowedTypes.has(file.type)) {
                    error = "File type not supported";
                }
                uploadQueue.push({
                    id: fileCounter++,
                    file: file,
                    error: error,
                    status: error || "Ready to upload",
                    name: uploadType === "default" ? null : uploadType
                });
                uploadQueue = uploadQueue;
            }
        }
    }

    function removeFromUploadQueue(index) {
        uploadQueue.splice(index, 1);
        uploadQueue = uploadQueue;
    }

    async function upload() {
        confirm = false;
        working = true;
        for (let i = 0; i < uploadQueue.length; i++) {
            const file = uploadQueue[i];
            if (file.error) {
                uploadQueue[i].status = `Skipped: ${file.error}`;
                continue;
            }
            // make filename
            let ext = file.file.name.substring(file.file.name.lastIndexOf('.') + 1);
            let fn = `${uploadType === "default" ? `Upload-${getFormattedDateForUpload()}` : uploadType}-${i}.${ext}`;

            const uploadParams = {
                file: file.file, name: fn, progress: e => {
                    if (e.type === "loadstart") {
                        uploadQueue[i].status = `Uploading...`;
                    } else if (e.type === "progress") {
                        uploadQueue[i].status = `Uploading (${(
                            (e.loaded / e.total) *
                            100.0
                        ).toLocaleString("en-GB", {maximumFractionDigits: 1})}%)`;
                    } else if (e.type === "load") {
                        uploadQueue[i].status = "Processing...";
                    } else if (e.type === "error") {
                        uploadQueue[i].status = "Failed";
                    }
                }
            }
            let result = await uploadDocument(uploadType, uploadParams.file, uploadParams.name, uploadParams.progress);
            if (result.doc) {
                uploadQueue[i].doc = result.doc;
            }
            uploadQueue[i].status = result.success ? "Done!" : "Failed";
        }
        const completed = uploadQueue.filter(f => f.doc);
        const failed = uploadQueue.filter(f => !f.doc);
        uploadQueue = failed;
        working = false;
        if (completed.length > 0) dispatch('uploaded', {completed});
        if (failed.length > 0) dispatch('failed', {failed});
    }

</script>

<div class="uploader">
    {#if uploadQueue.length}
        <Row>
            {#each uploadQueue as doc, index (`${doc.fileName}_${index}`)}
                <Col xs="12" sm="6" md="4" lg="3">
                    <UploadFile closable on:remove={() => removeFromUploadQueue(index)}>
                        <h6 class="file-name mb-1">{doc.file.name}</h6>
                        <p class="mb-0">{doc.status}</p>
                    </UploadFile>
                </Col>
            {/each}
        </Row>
    {/if}
    <div class="uploader-buttons mt-1">
        <input class="d-none" bind:this={fileSelect} on:change={addToUploadQueue} type="file" id="fileSelect" multiple/>

        <Button
                color={ uploadQueue.length === 0 ? `success` : `primary`}
                on:click={() => browse(uploadType)}
                disabled={working}><Icon name="file-plus" class="me-2"/>Add File
        </Button>

        {#if uploadQueue.length}
            <Button
                    color="success"
                    on:click="{() => { confirm = true; }}"
                    disabled={working}><Icon name="upload" class="me-2"/>Upload
            </Button>
            <Modal header="Ready..." isOpen={confirm} on:close={() => confirm = false}>
                <ModalBody>
                    <p>Have you added all the documents you need to upload?</p>
                    <p>If not, click no and then click Add for each document you need to send us, otherwise click Upload to start uploading.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" on:click={() => confirm = false}>No</Button>
                    <Button color="success" on:click={() => upload()}>Upload</Button>
                </ModalFooter>
            </Modal>
        {/if}

    </div>
</div>
