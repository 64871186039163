<script>
    import {Button, Container, FormGroup, Icon, Input, Spinner} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";

    import { router } from "tinro";
    import { activePortal } from "../../Shared/Data/Portal/portalStore";
    import {
        appFormResult,
        checkCaseForNextStep, completeIdentity, getIdentity
    } from "../../Shared/Data/Tool/toolStore";
    import {onMount} from "svelte";
    import countries from "../../Shared/Data/Lists/countries";

    let currentRoute = null;
    let working = false;
    let identityDocumentType = null;
    let identityCountryIssued = 'GBR';

    onMount(async () => {
        // keep track of route for page loads
        router.subscribe((x) => {
            currentRoute = x;
        });
        // check url for completion of kyc
        await checkForKycCompletion();
        // check for kyc uri to send user to
        checkForKycUri();
    })

    $: isValid = identityDocumentType !== null && identityCountryIssued !== null;
    async function checkForKycCompletion() {
        let queryToken = currentRoute && currentRoute.query && currentRoute.query.token ? currentRoute.query.token : null;
        let currentToken = $appFormResult && $appFormResult.token ? $appFormResult.token : null;
        if (queryToken && currentToken && queryToken === currentToken) {
            working = true;
            await completeIdentity();
            checkCaseForNextStep();
        }
    }
    function checkForKycUri() {
        let identityUri = $appFormResult && $appFormResult.journey && $appFormResult.journey.journey ? $appFormResult.journey.journey.kycUri : null;
        let identityCompleted = $appFormResult && $appFormResult.journey && $appFormResult.journey.journey ? $appFormResult.journey.journey.kycCompleted : null;
        if (identityUri && identityCompleted === false) {
            working = true;
            window.location = identityUri;
        }
    }
    async function startIdentity() {
        if (!isValid) return;
        working = true;
        let redirectUri = `${window.location}?token=${$appFormResult.token}`;
        await getIdentity(identityDocumentType, identityCountryIssued, redirectUri);
        working = false;
        checkForKycUri();
    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Confirm your identity`}</title>
</svelte:head>

<Container fluid class="identity d-flex position-relative">
    <Container class="py-2 mb-4">
        <h2 class="my-3 mb-4">Identity Check</h2>
        <p>We need to confirm your identity to proceed with your {$activePortal.term.toLowerCase()}.</p>
        <p>Please state which type of document you would prefer to use</p>
        <h5>Document Type</h5>
        <FormGroup>
            <Input
                    bsSize="lg"
                    type="radio"
                    id="drivingLicence"
                    value="driving_licence"
                    label="Driving Licence"
                    bind:group={identityDocumentType}
            />
            <Input
                    bsSize="lg"
                    type="radio"
                    id="passport"
                    value="passport"
                    label="Passport"
                    bind:group={identityDocumentType}
            />
        </FormGroup>
        <h5>Country Issued</h5>
        <FormGroup>
            <Input
                    bsSize="lg"
                    type="select"
                    id="mortgageLender"
                    bind:value={identityCountryIssued}
            >
                {#each countries as {code, country}}
                    <option value={code}>{country}</option>
                {/each}
            </Input>
        </FormGroup>
        <div class="d-flex">
            <Button disabled={working} color="success" on:click={startIdentity}>Continue<Icon name="arrow-right-circle-fill" class="ms-1" /></Button>
            {#if working}
                <Spinner class="ms-2" color="success" />
            {/if}
        </div>
    </Container>
</Container>

<Footer/>

<style>
</style>
