export const appFormModel = {
    firstName: null,
    lastName: null,
    gender: null,
    dob: null,
    email: null,
    phoneNumber: null,
    address: {},
    agree: false,
    flags: {
        proceed: false,
        submit: false,
        success: null
    }
};