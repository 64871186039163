<script>
    import {
        Button,
        Col,
        Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
        Icon,
        Offcanvas,
        OffcanvasBody,
        Row,
        Spinner
    } from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";

    import {activePortal, isLoan} from "../../Shared/Data/Portal/portalStore";
    import {
        sourcingResults, selectedProductType, filters, setSelectedProduct, isSourcingResults, gotoLocation
    } from "../../Shared/Data/Tool/toolStore";
    import Results from "../../Shared/Components/Results/Results.svelte";
    import ResultsOverview from "../../Shared/Components/Results/ResultsOverview.svelte";
    import UpdateParams from "../../Shared/Components/Results/SourcingParameters.svelte";
    import Filters from "../../Shared/Components/Results/Filters.svelte";
    import {fixedRates} from "../../Shared/Data/Lists/productTypes";
    import sortBy from "../../Shared/Data/Lists/sortBy";
    import Info from "../../Shared/Components/Info/Info.svelte";
    import {watch} from "../../Shared/Data/Utils/utils";

    //
    // Get Started
    //
    function getStarted() {
        gotoLocation('get-started');
    }

    //
    // Product results, filtered and sorted based on selection
    //
    $: selected = $sourcingResults ? $sourcingResults.find(x => x.quoteType === $selectedProductType) : null;
    $: products = selected && selected.products ? selected.products : [];
    $: initialQuoteResults = products
        .filter(x => {
            if ($filters.rateType === "all") {
                return true;
            } else if ($filters.rateType === 'fixed') {
                return x.rates[0].type === 'fixed';
            } else if ($filters.rateType === 'variable') {
                return x.rates[0].type === 'variable';
            } else if ($filters.rateType === 'tracker') {
                return x.rates[0].type === 'tracker';
            }
            return false;
        })
        .filter(x => {
            if ($filters.rateType === 'fixed' || $filters.rateType === 'tracker') {
                if ($filters.fixedType !== "all") {
                    let rate = fixedRates.find(x => x.value === $filters.fixedType);
                    if (rate != null) {
                        let rateTerm = x.rates[0].term;
                        return (rateTerm >= rate.minTerm && rateTerm <= rate.maxTerm);
                    }
                }
            }
            return true;
        })
        .sort((a, b) => {
            if ($filters.sortBy === 'initialRate') {
                return Number(a.rates[0].rate) - Number(b.rates[0].rate);
            } else if ($filters.sortBy === 'initialRepayment') {
                return Number(a.rates[0].monthlyRepayment) - Number(b.rates[0].monthlyRepayment);
            } else if ($filters.sortBy === 'aprc') {
                return Number(a.aprc) - Number(b.aprc);
            } else if ($filters.sortBy === 'totalFees') {
                let feeA = Number(a.fees.reduce((x, y) => x + y.amount, 0)) || 0;
                let feeB = Number(b.fees.reduce((x, y) => x + y.amount, 0)) || 0;
                return feeA - feeB;
            } else if ($filters.sortBy === 'totalRepayable') {
                let totalRepayableA = a.rates.length ? a.rates.reduce((a, b) => a + (b.monthlyRepayment * b.term), 0) : 0;
                let totalRepayableB = b.rates.length ? b.rates.reduce((a, b) => a + (b.monthlyRepayment * b.term), 0) : 0;
                return totalRepayableA - totalRepayableB;
            } else {
                return Number(a.trueCost) - Number(b.trueCost);
            }
        });

    //
    // Pager
    //
    let pageIndex = 0;
    let itemsPerPage = 10;

    watch(filters, (updatedFilters) => {
        pageIndex = 0;
    });

    $: quotePageData = {
        index: pageIndex,
        page: pageIndex + 1,
        pages: Math.ceil(initialQuoteResults.length / itemsPerPage),
        total: initialQuoteResults.length,
        perPage: itemsPerPage,
        start: pageIndex * itemsPerPage,
        end: (pageIndex * itemsPerPage) + itemsPerPage
    };

    //
    // Select Product
    //
    function selectProduct(event) {
        setSelectedProduct(event.detail);
        gotoLocation('proceed');
    }

    //
    // Edit Overlay
    //
    let showUpdateParams = false;
    const toggleParams = () => (showUpdateParams = !showUpdateParams);

    //
    // Filter overlay
    //
    let showFilters = false;
    const toggleFilters = () => (showFilters = !showFilters);

    //
    // Info overlay
    //
    let showInfo = false;
    const toggleInfo = () => (showInfo = !showInfo);

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

<Container fluid class="initial-quotes position-relative">
    <div class="d-flex align-items-start justify-content-start w-100 h-100 py-3">
        {#if $isSourcingResults === true}
            <Container fluid class="h-100 d-flex flex-column align-items-center justify-content-center bg-light py-5" style="top:0; bottom: 0; left: 0; right: 0;">
                <h3 class="text-center">Searching...</h3>
                <h5 class="text-center text-muted">Please wait while we search for products...</h5>
                <Spinner color="success" />
            </Container>
        {:else}
            {#if $sourcingResults === null}
                <Container fluid class="h-100 d-flex flex-column align-items-center justify-content-center bg-light py-5" style="top:0; bottom: 0; left: 0; right: 0;">
                    <h3 class="text-center">Begin your search</h3>
                    <h5 class="text-center text-muted">Answer a few questions and we will find the best products for you!</h5>
                    <Button size="lg" color="success" on:click={getStarted} >Get started...</Button>
                </Container>
            {:else}
                {#if $sourcingResults.length === 0}
                    <Container fluid class="h-100 d-flex flex-column align-items-center justify-content-center bg-light py-5" style="top:0; bottom: 0; left: 0; right: 0;">
                        <h3 class="text-center">No products found...</h3>
                        <h5 class="text-center text-muted">Please review your answers and try again.</h5>
                        <Button size="lg" color="success" on:click={getStarted} >Get started...</Button>
                    </Container>
                {:else}
                    <Row class="flex-fill w-100">
                        <Col xs="12" md="4" lg="3" class="mb-2 d-flex d-md-block align-items-center justify-content-between">
                            <div class="d-none d-md-block">
                                <UpdateParams on:info={toggleInfo} />
                                <Filters on:info={toggleInfo} />
                            </div>
                        </Col>
                        <Col xs="12" md="8" lg="9">
                            {#if !$isLoan}
                                <ResultsOverview />
                            {/if}
                            <div class="d-flex align-items-center mb-3 d-md-none">
                                <Button class="me-1" color="primary" on:click={toggleParams}>Edit<Icon name="pencil" class="ms-2" /></Button>
                                <Button class="me-1" color="primary" on:click={toggleFilters}>Filter<Icon name="filter" class="ms-2" /></Button>
                            </div>
                            <div class="shadow p-2 mb-3">
                                <div class="d-flex align-items-center">
                                    <label for="sortBy">Sort By</label>
                                    <Button color="link" class="p-0 ms-2" on:click={toggleInfo}>
                                        <Icon name="info-circle-fill fs-5" />
                                    </Button>
                                </div>
                                <Dropdown class="w-100">
                                    <DropdownToggle color="light" class="w-100 d-flex align-items-center justify-content-between" caret>
                                        {sortBy.find(x => x.value === $filters.sortBy).label}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {#each sortBy as sort}
                                            <DropdownItem
                                                    active={$filters.sortBy === sort.value}
                                                    on:click={() => $filters.sortBy = sort.value}
                                            >
                                                {sort.label}
                                            </DropdownItem>
                                        {/each}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Results
                                    results={initialQuoteResults}
                                    pageData={quotePageData}
                                    on:pageChanged={e => pageIndex = e.detail}
                                    on:perPageChanged={e => { pageIndex = 0; itemsPerPage = e.detail }}
                                    on:selectedProduct={selectProduct}
                            />
                        </Col>
                    </Row>
                {/if}
            {/if}
        {/if}

        <Offcanvas
                isOpen={showUpdateParams}
                toggle={toggleParams}
                header="Update Term"
                placement="end"
                scroll
                body={false}
        >
            <OffcanvasBody class="py-0">
                <UpdateParams
                        on:info={toggleInfo}
                        on:update={() => {showUpdateParams = false}}
                />
            </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
                isOpen={showFilters}
                toggle={toggleFilters}
                header="Change Filters"
                placement="end"
                scroll
                body={false}
        >
            <OffcanvasBody class="py-0">
                <Filters
                        on:info={toggleInfo}
                        on:update={() => {showUpdateParams = false}}
                />
                <div class="bg-light p-2">
                    <Button
                            color="primary"
                            class="d-block ms-auto"
                            on:click={toggleFilters}
                    >Update</Button>
                </div>
            </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
                isOpen={showInfo}
                toggle={toggleInfo}
                header="Help"
                placement="end"
                scroll
                body={false}
        >
            <OffcanvasBody class="py-0">
                <Info/>
            </OffcanvasBody>
        </Offcanvas>
    </div>
</Container>

<Footer/>

<style>
</style>
