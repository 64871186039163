<script>
    import {createEventDispatcher} from "svelte";
    import {
        Button,
        Dropdown, DropdownItem,
        DropdownMenu,
        DropdownToggle,
        Pagination,
        PaginationItem,
        PaginationLink
    } from "sveltestrap";

    const dispatch = createEventDispatcher();

    export let pageData = {
        index: 0,
        page: 1,
        pages: 1,
        total: 0,
        perPage: 10,
        start: 0,
        end: 0
    };

    $: navigationLinks = () => {
        let links = [];
        let siblings = 2;
        let start = pageData.index - siblings;
        let end = pageData.index + siblings;
        if (start < 0) {
            end += Math.abs(start);
            start = 0;
        }
        if (end > pageData.pages) {
            start -= end - pageData.pages;
            end = pageData.pages;
        }
        if (start < 0) start = 0;
        if (end > pageData.pages) end = pageData.pages;
        for (let i = start; i < end; i++) {
            links.push(i);
        }
        return links;
    }

    function change(index) {
        dispatch("pageChanged", index);
    }

    function perPage(total) {
        dispatch("perPageChanged", total);
    }

</script>

<div class="pager w-100 shadow p-2 d-flex align-items-center justify-content-between flex-wrap">
    <Pagination listClassName="mb-3 mb-sm-0">
        <PaginationItem disabled={pageData.index === 0}>
            <PaginationLink first on:click={() => change(0)}/>
        </PaginationItem>
        <PaginationItem disabled={pageData.index === 0}>
            <PaginationLink previous on:click={() => change(pageData.index - 1)}/>
        </PaginationItem>
        {#each navigationLinks() as i}
            <PaginationItem active={pageData.index === i}>
                <PaginationLink on:click={() => change(i)}>
                    {i + 1}
                </PaginationLink>
            </PaginationItem>
        {/each}
        <PaginationItem disabled={pageData.index === pageData.pages - 1}>
            <PaginationLink next on:click={() => change(pageData.index + 1)}/>
        </PaginationItem>
        <PaginationItem disabled={pageData.index === pageData.pages - 1}>
            <PaginationLink last on:click={() => change(pageData.pages - 1)}/>
        </PaginationItem>
    </Pagination>
    <div class="ms-2 text-muted">
        {pageData.start + 1} - {pageData.end < pageData.total ? pageData.end : pageData.total } of {pageData.total} products
    </div>
    <Dropdown class="ms-auto">
        <DropdownToggle caret color="outline-primary" >{pageData.perPage} per page</DropdownToggle>
        <DropdownMenu>
            <DropdownItem active={ pageData.perPage === 10 } on:click={() => perPage(10)}>10</DropdownItem>
            <DropdownItem active={ pageData.perPage === 25 } on:click={() => perPage(25)}>25</DropdownItem>
            <DropdownItem active={ pageData.perPage === 50 } on:click={() => perPage(50)}>50</DropdownItem>
            <DropdownItem active={ pageData.perPage === 100 } on:click={() => perPage(100)}>100</DropdownItem>
        </DropdownMenu>
    </Dropdown>
</div>
