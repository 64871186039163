export default {
  async portals() {
    let portals = [];
    try {
      let result = await fetch("/api/portals", {
        method: "GET"
      });
      if (result.status === 200) {
        portals = await result.json();
      }
    }
    catch (e) {
      //console.log(e);
    }
    return portals;
  }
}
