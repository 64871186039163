<script>
    import {onMount} from "svelte";
    import {Button, Container, Spinner} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";

    import { router } from "tinro";
    import { activePortal } from "../../Shared/Data/Portal/portalStore";
    import {
        appForm,
        appFormResult, checkCaseForNextStep, gotoLocation,
        selectedProduct,
        selectedProductType,
        submitAppForm
    } from "../../Shared/Data/Tool/toolStore";

    onMount(async () => {
        if ($selectedProduct === null || $selectedProductType === null) {
            gotoLocation('quotes');
            return;
        }
        if ($appForm.proceed === false) {
            gotoLocation('proceed');
            return;
        }
        await submit()
    });

    $: consolidation = $appFormResult !== null
        && $appFormResult.quotes != null
        && ($appFormResult.quotes.find(x => x.quoteType === "consolidationExample") ? $appFormResult.quotes.find(x => x.quoteType === "consolidationExample") : null);

    $: consolidationProducts = consolidation && consolidation.products ? consolidation.products : [];
    $: hasConsolidation = consolidationProducts.length > 0;

    async function submit() {
        $appForm.flags.submit = true;
        $appForm.flags.success = null;
        await submitAppForm();
        if ($appFormResult && $appFormResult.journey && $appFormResult.journey.journey) {
            checkCaseForNextStep();
        }
    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Submitting your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

<Container class="flex-grow-1 py-5 d-flex flex-column align-items-center justify-content-center">
    {#if $appForm.flags.success === null}
        <h1>Submitting...</h1>
        <p>This won't take a moment...</p>
        <Spinner type="grow" color="primary" />
    {/if}
    {#if $appForm.flags.success === false}
        <h1>There was a problem</h1>
        <p>There was a problem submitting your request at this time.</p>
        <div class="d-flex align-items-center" style="gap:0.5rem;">
            <Button color="primary" on:click={async () => await submit()}>Try again</Button>
            <Button color="secondary" on:click={async () => gotoLocation('quotes')}>Back</Button>
        </div>
    {/if}
</Container>

<Footer/>

<style>
</style>
