<script>
    import {Container} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";
    import { activePortal } from "../../Shared/Data/Portal/portalStore";
    import {checkCaseForNextStep} from "../../Shared/Data/Tool/toolStore";
    import Callback from "../../Shared/Components/Callback/Callback.svelte";

    async function submitted() {
        checkCaseForNextStep();
    }

</script>

<svelte:head>
<title>{`${$activePortal.brand.name} - Proceed with your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

<Container fluid class="callback d-flex position-relative">
    <Container class="py-2">
        <h2 class="my-3 mb-4">We need to speak to you...</h2>
        <h6>To continue with your {$activePortal.term.toLowerCase()} we need to speak to you to confirm a few details.</h6>
        <Callback on:submitted={submitted} />
    </Container>
</Container>

<Footer/>

<style>
</style>
