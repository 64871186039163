import {get} from "svelte/store";
import {activePortal} from "../Portal/portalStore";
import tracking from "../../../tracking";

export default {
  async quote(params) {
    try {

      let result = await fetch(`/api/${get(activePortal).api}/lead/quote`, {
        method: "POST",
        body: JSON.stringify(params)
      });
      if (result.status === 200) {
        return await result.json();
      }
    }
    catch (e) {
      console.log(e);
    }
    return [];
  },
  async caseDetails(accessCode) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${accessCode}`, { method: 'GET' });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async submit(token, sourcingParameters, sourcingFilters, form, product, type) {
    try {
      let trackingParams = null;
      try {
        const trackingData = await tracking();
        if (trackingData && trackingData.query && trackingData.query.utm_source && trackingData.query.utm_source.length) {
          trackingParams = { source: trackingData.query.utm_source[0] };
        }
      } catch {
        // no-op
      }

      let result = await fetch(`/api/${get(activePortal).api}/lead/submit`, {
        method: "POST",
        body: JSON.stringify({
          token: token,
          sourcingParameters: sourcingParameters,
          sourcingFilters: sourcingFilters,
          form: form,
          product: product,
          type: type,
          tracking: trackingParams
        })
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async updateProduct(token, product) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${token}/product`, {
        method: "PUT",
        body: JSON.stringify(product)
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async esign(token) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${token}/esign`, {
        method: "POST",
        body: null
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async esignCompleted(token) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${token}/esign`, {
        method: "PUT",
        body: null
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async identity(token, body) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${token}/kyc`, {
        method: "POST",
        body: JSON.stringify(body)
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async identityCompleted(token) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${token}/kyc`, {
        method: "PUT",
        body: null
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async callback(token, body) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${token}/callback`, {
        method: "POST",
        body: JSON.stringify(body)
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  async expenditure(token, id) {
    try {
      let result = await fetch(`/api/${get(activePortal).api}/lead/${token}/expenditure/${id}`, {
        method: "POST",
        body: null
      });
      if (result.status === 200) {
        return {
          data: await result.json(),
          success: true
        };
      }
    }
    catch (e) {
      console.log(e);
    }
    return {
      success: false
    }
  },
  upload(token,uploadType, file, name, progress) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("loadstart", progress);
      xhr.upload.addEventListener("progress", progress);
      xhr.upload.addEventListener("load", progress);
      xhr.upload.addEventListener("error", progress);
      xhr.addEventListener("load", async () => {
        if (xhr.status === 200) {
          resolve({success: true, doc: JSON.parse(xhr.responseText)});
          return;
        }
        resolve({success: false});
      });
      xhr.addEventListener("error", () => resolve({succcess: false}));
      xhr.open("POST", `/api/${get(activePortal).api}/lead/${token}/upload/${uploadType}?fileName=${name || file.name}`, true);
      xhr.setRequestHeader("Home-Type", file.type);
      xhr.send(file);
    });
  }
}
