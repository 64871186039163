export default async function () {
    const stored = sessionStorage ? sessionStorage.getItem("bx_trk") : null;
    let trackData = stored ? JSON.parse(stored) : {landingURL: location.href, referralURL: document.referrer};
    if (!stored && location.search) {
        const params = location.search
            ? location.search
                .slice(1)
                .split("&")
                .map(p => {
                    const parts = p.split("=");
                    return {
                        key: decodeURIComponent(parts[0]),
                        value: parts.length > 1 ? decodeURIComponent(parts[1]) : ""
                    };
                })
            : [];
        const query = {};
        for (const param of params) {
            if (param.key in query) {
                query[param.key].push(param.value);
            } else {
                query[param.key] = [param.value];
            }
        }
        trackData.query = query;
        if (sessionStorage) {
            sessionStorage.setItem("bx_trk", JSON.stringify(trackData));
        }
    }
    return trackData;
}
