<script>
    import {createEventDispatcher, tick} from 'svelte';
    const dispatch = createEventDispatcher();

    import Uploader from "./Uploader.svelte";
    export let uploadName = "";
    export let uploadType = "default";

</script>

<div class={`${$$props.class ? $$props.class : ''}`}>
    <p>
        We can accept photos or PDF documents up to 25MB in size, and you can upload multiple files at a time.
    </p>
    <Uploader uploadName={uploadName}
              uploadType={uploadType}
              on:back={(e) => dispatch('back', e)}
              on:uploaded={(e) => dispatch('uploaded', e) } />
</div>
