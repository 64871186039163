import {derived} from "svelte/store";
import {onDestroy} from "svelte";

export function watch(deps, fn) {
  const unsubscribe = derived(deps, values => values).subscribe(fn);
  onDestroy(unsubscribe);
}

export function formatLoanTerm(term) {
  const years = Math.floor(term / 12);
  const months = term % 12;
  const yearsText = years === 1 ? "1 year" : `${years} years`;
  const monthsText = months === 0 ? "" : months === 1 ? " 1 month" : ` ${months} months`;
  return yearsText + monthsText;
}

export function yearsAndMonthsFromTerm(term) {
    const years = Math.floor(term / 12);
    const months = term % 12;
    return { years, months };
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getFormattedDateForUpload() {
  const dt = new Date(Date.now());
  let cd = [
    dt.getFullYear(),
    dt.getMonth() + 1 < 10 ? '0' : '',
    dt.getMonth() + 1,
    dt.getDate() < 10 ? '0' : '',
    dt.getDate(),
    '-',
    dt.getHours() < 10 ? '0' : '',
    dt.getHours(),
    dt.getMinutes() < 10 ? '0' : '',
    dt.getMinutes(),
  ]
  return cd.map(x => x.toString()).filter(x => x.length > 0).join('');
}

