import {get, writable} from "svelte/store";
import toolApi from "./toolApi";

//
// Import default models
//
import {filtersModel} from "./Models/filters";
import {sourcingParametersModel} from "./Models/sourcing-parameters";
import {appFormModel} from "./Models/appForm";
import {yearsAndMonthsFromTerm} from "../Utils/utils";
import {active, router} from "tinro";
import {activePortal, isLoan} from "../Portal/portalStore";

//
// Storage Keys
//
const storageKeys = {
    accessCode: "access-code",
    sourcingParameters: "sourcing-parameters",
    sourcingResults: "sourcing-results",
    selectedProduct: "product",
    selectedProductType: "type",
    appForm: "form",
    appFormResult: "form-result"
}

//
// Fetch from storage
//
const storedAccessCode = sessionStorage.getItem(storageKeys.accessCode);
const storedSourcingParameters = JSON.parse(sessionStorage.getItem(storageKeys.sourcingParameters));
const storedSourcingResults = JSON.parse(sessionStorage.getItem(storageKeys.sourcingResults));
const storedSelectedProduct = JSON.parse(sessionStorage.getItem(storageKeys.selectedProduct));
const storedSelectedProductType = JSON.parse(sessionStorage.getItem(storageKeys.selectedProductType));
const storedAppForm = JSON.parse(sessionStorage.getItem(storageKeys.appForm));
const storedAppFormResult = JSON.parse(sessionStorage.getItem(storageKeys.appFormResult));

export function clearSession() {
    sessionStorage.clear();
}

//
// Data
//
export const accessCode = writable(storedAccessCode || null);
export const filters = writable(filtersModel)
export const sourcingParameters = writable(storedSourcingParameters || sourcingParametersModel);
export const sourcingResults = writable(storedSourcingResults);
export const isSourcingResults = writable(false);
export const selectedProductType = writable(storedSelectedProductType ||
storedSourcingResults && storedSourcingResults.length > 0
    ?
    (storedSourcingResults.find(x => x.quoteType === 'productTransfer')
            ?
            'productTransfer' :
            storedSourcingResults[0].quoteType
    )
    :
    null);
export const selectedProduct = writable( storedSelectedProduct || null);
export const appForm = writable( storedAppForm || appFormModel);
export const appFormResult = writable(storedAppFormResult || null);

//
// Progress Logic
//
export function checkCaseForNextStep() {
    let afr = get(appFormResult);
    let j = afr && afr.journey ? afr.journey : null;
    let jj = j && j.journey ? j.journey : null;
    if (!j && !jj) return;
    switch (jj.journeyType) {
        case "digitalPack":
            if (jj.documentsUploaded === false) {
                gotoLocation('income');
                return;
            }
            if (jj.incomeAndExpenditureFormCompleted === false) {
                gotoLocation('expenditure');
                return
            }
            break;
        case "productTransfer":
            if (jj.eSignCompleted === false) {
                gotoLocation('product-transfer');
                return;
            }
            if (jj.kycCompleted === false) {
                gotoLocation('identity');
                return;
            }
            break;
        case "callback":
            if (j.callbackTime === null) {
                gotoLocation('callback');
                return;
            }
            break;
    }
    // if we get here, then the case is complete
    gotoLocation('thank-you');
}

export function gotoLocation(location) {
    let $activePortal = get(activePortal);
    router.goto(`/${$activePortal.slug.long}/${location}/`);
}

//
// Load case details using Access Code
//
function mapSourcingParamsFromCase(params) {
    let mortgageTerm = yearsAndMonthsFromTerm(params.chargeTermRemaining);
    let loan = get(isLoan);
    return {
        lookingFor: loan ? 'loan' : (params.chargeBalance || 0) > 0 ? 'remortgage' : 'purchase',
        propertyValue: params.propertyValue,
        mortgageOutstanding: params.chargeBalance -  (params.raiseAmount || 0),
        mortgageTermRemaining: {
            years: mortgageTerm.years,
            months: mortgageTerm.months
        },
        mortgageFixed: false,
        mortgageFixedTermRemaining: {
            years: null,
            months: 0
        },
        mortgageLender: params.chargeLender,
        raise: loan ? false : params.raiseAmount > 0 && (params.chargeBalance || 0) > 0,
        raiseAmount: params.raiseAmount,
        employmentStatus: params.employmentStatus,
        grossAnnualIncome: params.grossAnnualIncome
    };
}

export async function loadCaseDetails(ac) {
    let result = await toolApi.caseDetails(ac);
    if (result.success) {
        // set access code
        accessCode.set(ac);
        // clear session storage
        clearSession();
        // set sourcing parameters
        sourcingParameters.set(mapSourcingParamsFromCase(result.data.sourcingParameters));
        // set selected product
        selectedProduct.set(result.data.selectedProduct);
        // set app form result
        appFormResult.set({
            journey: result.data,
            token: ac
        });
        // save all to session storage
        sessionStorage.setItem(storageKeys.accessCode, get(accessCode));
        sessionStorage.setItem(storageKeys.sourcingParameters, JSON.stringify(get(sourcingParameters)));
        sessionStorage.setItem(storageKeys.selectedProduct, JSON.stringify(get(selectedProduct)));
        sessionStorage.setItem(storageKeys.appFormResult, JSON.stringify(get(appFormResult)));
    }
    return result;
}

//
// Product
//
export function setSelectedProduct(product) {
    selectedProduct.set(product);
    sessionStorage.setItem(storageKeys.selectedProduct, JSON.stringify(get(selectedProduct)));
    sessionStorage.setItem(storageKeys.selectedProductType, JSON.stringify(get(selectedProductType)));
}

export async function updateSelectedProduct(product) {
    let token = get(appFormResult).token;
    let result = await toolApi.updateProduct(token, product);
    if (result.success) {
        setSelectedProduct(product);
    }
    return result.success;
}

//
// Sourcing
//
function mapSourcingParams() {
    let _sourcingParameters = get(sourcingParameters);
    return {
        propertyValue: _sourcingParameters.propertyValue,
        chargeBalance: ["remortgage", "loan"].includes(_sourcingParameters.lookingFor) ? _sourcingParameters.mortgageOutstanding : 0,
        chargeTermRemaining: ((_sourcingParameters.mortgageTermRemaining.years ?? 0) * 12 ) + _sourcingParameters.mortgageTermRemaining.months,
        employmentStatus: _sourcingParameters.employmentStatus,
        grossAnnualIncome: _sourcingParameters.grossAnnualIncome,
        raiseAmount: _sourcingParameters.raise === true || ["purchase", "loan"].includes(_sourcingParameters.lookingFor) ? _sourcingParameters.raiseAmount ?? 0 : 0,
        chargeLender: ["remortgage"].includes(_sourcingParameters.lookingFor) ? _sourcingParameters.mortgageLender : ""
    }
}

export async function source() {
    // set working
    isSourcingResults.set(true);
    // clear results
    sourcingResults.set([]);
    // map params
    let params = mapSourcingParams();
    // save params
    sessionStorage.setItem(storageKeys.sourcingParameters, JSON.stringify(get(sourcingParameters)));
    // get quotes
    let quotes = await toolApi.quote(params);
    // get first key from quotes
    if (quotes.length > 0) {
        if (quotes.find(x => x.quoteType === 'productTransfer')) {
            selectedProductType.set('productTransfer');
        } else {
            selectedProductType.set(quotes[0].quoteType);
        }
    } else {
        selectedProductType.set(null);
    }
    // save product type
    sessionStorage.setItem(storageKeys.selectedProductType, JSON.stringify(get(selectedProductType)));
    // set results
    sourcingResults.set(quotes);
    // save to local storage
    sessionStorage.setItem(storageKeys.sourcingResults, JSON.stringify(get(sourcingResults)));
    // set not working
    isSourcingResults.set(false);
}

//
// App Form
//
export function saveAppForm() {
    sessionStorage.setItem(storageKeys.appForm, JSON.stringify(get(appForm)));
}

export function setAppFormResult(result) {
    // set result
    appFormResult.set(result);
    // save result
    saveAppFormResult();
}

export function saveAppFormResult() {
    sessionStorage.setItem(storageKeys.appFormResult, JSON.stringify(get(appFormResult)));
}

export async function submitAppForm() {
    // reset result
    setAppFormResult(null);
    // get local copies
    let afr = get(appFormResult);
    let _token = afr && afr.token ? afr.token : null;
    let _appForm = get(appForm);
    let _sourcingParameters = mapSourcingParams();
    let _sourcingFilters = get(filters);
    let _product = get(selectedProduct);
    let _productType = get(selectedProductType);
    // submit
    let result = await toolApi.submit(_token, _sourcingParameters, _sourcingFilters, _appForm, _product, _productType);
    // update app form
    appForm.set({
        ..._appForm,
        flags: {
            ..._appForm.flags,
            submit: false,
            success: result.success
        }
    });
    // check result
    if (result.success) {
        setAppFormResult(result.data);
    }
}

//
// Upload
//
export async function uploadDocument(uploadType, file, name, progress) {
    let afr = get(appFormResult);
    let token = afr && afr.token ? afr.token : null;
    let result = await toolApi.upload(token, uploadType, file, name, progress);
    let r = get(appFormResult);
    r.journey.journey.documentsUploaded = true;
    appFormResult.set(r);
    saveAppFormResult();
    return result;
}

//
// TypeForm / Expenditure
//
export async function expenditure(id) {
    let afr = get(appFormResult);
    let token = afr && afr.token ? afr.token : null;
    let result = await toolApi.expenditure(token, id);
    if (result.success) {
        let r = get(appFormResult);
        r = {
            ...r,
            journey: result.data
        };
        appFormResult.set(r);
        saveAppFormResult();
    }
    return result;
}

//
// Esign
//
export async function getEsignDocument() {
    let afr = get(appFormResult);
    let token = afr && afr.token ? afr.token : null;
    let result = await toolApi.esign(token);
    if (result.success) {
        let r = get(appFormResult);
        r = {
            ...r,
            journey: result.data
        };
        appFormResult.set(r);
        saveAppFormResult();
    }
    return result;
}

export async function completeEsignDocument() {
    let afr = get(appFormResult);
    let token = afr && afr.token ? afr.token : null;
    let result = await toolApi.esignCompleted(token);
    if (result.success) {
        let r = get(appFormResult);
        r = {
            ...r,
            journey: result.data
        };
        appFormResult.set(r);
        saveAppFormResult();
    }
    return result;
}

//
// Identity
//
export async function getIdentity(documentType, issuingCountry, redirectUri) {
    let afr = get(appFormResult);
    let token = afr && afr.token ? afr.token : null;
    let result = await toolApi.identity(token, {
        type: documentType,
        issuingCountry: issuingCountry,
        redirectUri: redirectUri
    });
    if (result.success) {
        let r = get(appFormResult);
        r = {
            ...r,
            journey: result.data
        };
        appFormResult.set(r);
        saveAppFormResult();
    }
    return result;
}

export async function completeIdentity() {
    let afr = get(appFormResult);
    let token = afr && afr.token ? afr.token : null;
    let result = await toolApi.identityCompleted(token);
    if (result.success) {
        let r = get(appFormResult);
        r = {
            ...r,
            journey: result.data
        };
        appFormResult.set(r);
        saveAppFormResult();
    }
    return result;
}

//
// Callback
//
export async function setCallback(callback) {
    let afr = get(appFormResult);
    let token = afr && afr.token ? afr.token : null;
    let result = await toolApi.callback(token, callback);
    if (result.success) {
        let r = get(appFormResult);
        r = {
            ...r,
            journey: result.data
        };
        appFormResult.set(r);
        saveAppFormResult();
    }
    return result;
}
