<script>
    import {activePortal} from "../Data/Portal/portalStore";
    import TrustBox from "../../Views/TrustBox/TrustBox.svelte";
    import {Container} from "sveltestrap";

    $: trustPilot = $activePortal.trustPilot;
</script>

{#if $activePortal.api !== undefined}
<footer class="bg-light border-top pb-2">
    {#if trustPilot != null}
        <Container>
            <TrustBox cssClass="padding-top"
                      templateId={ trustPilot.templateId }
                      businessUnitId={ trustPilot.businessUnitId }
                      domain={ $activePortal.brand.domain }
            />
        </Container>
    {/if}
    <Container>
        <p>
            This Indicative Quote does not constitute a mortgage offer and does not entitle its recipients to a mortgage advance from the lender. Exact figures and interest rates will be fully explained in your Mortgage Illustration, please ensure you fully read through this once you receive it. Any offer of a mortgage is subject to the prevailing terms and conditions and prior to the lenders full underwriting assessment.
        </p>
        {#if $activePortal.api === "second-charge"}
            <p>
                <strong>Representative Example</strong><br />
                If you borrow £30,000 over 180 months at a rate of 7.84% fixed for 5 years, you will pay 60 instalments of £309.72 per month followed by 120 months at a rate of 8.59% of £319.96 and a total amount payable of £57,153.40. This includes a broker fee of £1,995, a lender fee of £695 and the net loan interest of £24,253.85. The overall cost for comparison is 8.70% APRC Variable.
            </p>
        {/if}
        {#if $activePortal.api !== "second-charge"}
            <p>
                <strong>Representative Example</strong><br/>
                A mortgage of £135,000.00 payable over 20 years, initially on a fixed rate for 5 years at 4.59% and then on a variable rate of 6.29% for the remaining term would require 63 payments of £866.04 followed by payments of £966.15. The total amount payable would be £226,564.07 made up of the loan amount plus interest (£90,077.17) and fees (£995). The overall cost for comparison is 6.0% APRC representative.
            </p>
            <p>
                A mortgage of £135,000.00 payable over 20 years, initially on a tracker rate for 2 years at 3.24% and then on a variable rate of 6.49% for the remaining term would require 24 payments of £770.69 followed by payments of £990.41. The total amount payable would be £233,500.12 made up of the loan amount plus interest (£96,426.38) and fees (£995). The overall cost for comparison is 6.1% APRC representative.
            </p>
        {/if}
        <p>
            <strong
            >Your home may be repossessed if you do not keep up repayments on your mortgage.</strong
            >
        </p>
    </Container>
</footer>
{/if}
