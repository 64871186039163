<script>
    import {Container} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";
    import { activePortal } from "../../Shared/Data/Portal/portalStore";
    import TypeForm from "../../Shared/Components/TypeForm/TypeForm.svelte";
    import {
        appFormResult,
        checkCaseForNextStep,
        expenditure,
        gotoLocation,
        saveAppFormResult
    } from "../../Shared/Data/Tool/toolStore";

    $: typeFormId = () => {
        switch ($activePortal.api) {
            case "second-charge":
                return "ynEmumBZ";
            case "first-charge":
                return "j5u5tEMx";
            case "specialist-finance":
                return "";
        }
    };

    let typeFormResponseId = null;

    async function expenditureComplete(e) {
        typeFormResponseId = e.detail;
        await storeExpenditureTypeFormId();
    }

    async function expenditureFinished() {
        await storeExpenditureTypeFormId();
    }

    async function storeExpenditureTypeFormId() {
        if (typeFormResponseId) {
            await expenditure(typeFormResponseId);
            typeFormResponseId = null;
        }
        checkCaseForNextStep()
    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Expenditure for your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

{#if $appFormResult && $appFormResult.token}
    <Container fluid class="proceed d-flex position-relative">
        <Container class="py-2 mb-4">
            <TypeForm
                    formId={typeFormId()}
                    accessCode={$appFormResult.token}
                    on:completed={expenditureComplete}
                    on:finished={expenditureFinished}
            />
        </Container>
    </Container>
{/if}

<Footer/>

<style>
</style>
