<script>
    import {Alert, Button, Container, Icon, Spinner} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";
    import { activePortal } from "../../Shared/Data/Portal/portalStore";
    import {
        appFormResult, checkCaseForNextStep,
        completeEsignDocument,
        getEsignDocument
    } from "../../Shared/Data/Tool/toolStore";
    import ESign from "../../Shared/Components/ESign/ESign.svelte";

    let working = false;

    async function beginEsign() {
        working = true;
        await getEsignDocument();
    }

    async function signed(e) {
        await completeEsignDocument();
        moveToNextStep();
    }

    async function declined(e) {
        console.log('Declined', e);
        moveToNextStep();
    }

    async function error(e) {
        console.log('Error', e);
        moveToNextStep();
    }

    function moveToNextStep() {
        checkCaseForNextStep();
    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Product Transfer for your ${$activePortal.term.toLowerCase()}...`}</title>
</svelte:head>

<Container fluid class="product-transfer d-flex position-relative">
    <Container class="py-2 mb-4">
        {#if $appFormResult.journey.journey.eSignUri === null}
            <h2 class="my-3 mb-4">Important Information</h2>
            <p>We need you to electronically sign a document to confirm you understand the product you are applying for.</p>
            <h5>Select 'Continue' to read the document and sign.</h5>
            <div class="d-flex">
                <Button disabled={working} color="success" on:click={beginEsign}>Continue<Icon name="arrow-right-circle-fill" class="ms-1" /></Button>
                {#if working}
                    <Spinner class="ms-2" color="success" />
                {/if}
            </div>
        {:else}
            {#if working}
                <Container class="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                    <h1>Loading...</h1>
                    <p>One moment</p>
                    <Spinner type="grow" color="primary" />
                </Container>
            {/if}
            <ESign eSignUrl={$appFormResult.journey.journey.eSignUri}
                on:working={working = true}
                on:loaded={working = false}
                on:signed={signed}
                on:declined={declined}
                on:error={error}
            />
        {/if}
    </Container>
</Container>

<Footer/>

<style>
</style>
