<script>
    import { fly } from 'svelte/transition';
    import {onMount} from "svelte";

    import {Button, Container, FormGroup, Icon, Input} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";

    import { router } from "tinro";
    import { activePortal } from "../../Shared/Data/Portal/portalStore";
    import {appForm, gotoLocation, saveAppForm, selectedProduct} from "../../Shared/Data/Tool/toolStore";
    import Product from "../../Shared/Components/Results/Product.svelte";
    import LoqateAddressFinder from "../../Shared/Components/Address/LoqateAddressFinder.svelte";
    import {toTitleCase} from "../../Shared/Data/Utils/utils";

    onMount(async () => {
        if ($selectedProduct === null) {
            gotoLocation('quotes');
            return;
        }
        focusInput();
    });

    const steps = {
        yourName: 0,
        dob: 1,
        gender: 2,
        contact: 3,
        address: 4,
        finished: 5
    }

    let currentStep = steps.yourName;
    let currentStepSubmit = false;

    function backStep() {
        currentStep--;
    }

    $: isStepValid = () => {
        switch (currentStep) {
            case steps.yourName:
                return $appForm.firstName && $appForm.firstName.trim().length > 1 && $appForm.lastName && $appForm.lastName.trim().length > 1;
            case steps.dob:
                    let dob = $appForm.dob;
                    if (dob == null || dob.length < 10) return false;
                    let dobDate = new Date(dob);
                    if (isNaN(dobDate.getTime())) return false;
                    let age = Math.floor((new Date() - dobDate) / 1000 / 60 / 60 / 24 / 365);
                    return age >= 18 && age < 100;
            case steps.gender:
                return $appForm.gender !== null;
            case steps.contact:
                let email = $appForm.email ? $appForm.email.trim() : '';
                let phone = $appForm.phoneNumber ? $appForm.phoneNumber.trim() : '';
                // check if email is valid
                let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailRegex.test(email) === false) return false;
                // check if phone is valid uk number
                let phoneRegex = /^((\+44\s?|0)7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;
                if (phoneRegex.test(phone) === false) return false;
                // return true if both are valid
                return true;
            case steps.address:
                return Object.keys($appForm.address).length > 0;
            case steps.finished:
                return $appForm.agree === true;
        }
    }

    function moveStep() {

        // set as submit attempt
        currentStepSubmit = true;

        // Validate
        if (isStepValid() === false) return;

        // Reset
        currentStepSubmit = false;

        // If finished, submit
        if (currentStep === steps.finished) {
            $appForm.flags.proceed = true;
            saveAppForm();
            gotoLocation('processing');
            return;
        }

        // Increase step
        currentStep++;

        // find input and focus
        focusInput();
    }

    function focusInput() {
        setTimeout(() => {
            let els = document.querySelectorAll('input,select');
            if (els != null && els.length > 0) els[0].focus();
        }, 500);
    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Proceed with your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

<Container fluid class="proceed d-flex position-relative">
    <Container class="py-2">
        {#if currentStep !== steps.finished}
            <h2 class="my-3 mb-4">Let's continue...</h2>
        {/if}
        {#if currentStep === steps.yourName}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Your name</h4>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="text"
                            id="appFormFirstName"
                            placeholder="First Name"
                            bind:value={$appForm.firstName}
                            on:keydown={(e) => {
                                if (e.key === 'Enter') moveStep();
                            }}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="text"
                            id="appFormLastName"
                            placeholder="Last Name"
                            bind:value={$appForm.lastName}
                            on:keydown={(e) => {
                                if (e.key === 'Enter') moveStep();
                            }}
                    />
                </FormGroup>
            </div>
        {/if}

        {#if currentStep === steps.dob}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Your date of birth</h4>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="date"
                            id="appFormDob"
                            placeholder="Date of Birth"
                            bind:value={$appForm.dob}
                            invalid={isStepValid() === false && currentStepSubmit === true}
                            feedback="A valid date and must be over 18"
                            on:keydown={(e) => {
                                if (e.key === 'Enter') moveStep();
                            }}
                    />
                </FormGroup>
            </div>
        {/if}

        {#if currentStep === steps.gender}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Gender<span class="small text-muted ms-2">required for identity check</span></h4>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="radio"
                            id="female"
                            value="female"
                            label="Female"
                            bind:group={$appForm.gender}
                            on:keydown={(e) => {
                                if (e.key === 'Enter') moveStep();
                            }}
                    />
                    <Input
                            bsSize="lg"
                            type="radio"
                            id="male"
                            value="male"
                            label="Male"
                            bind:group={$appForm.gender}
                            on:keydown={(e) => {
                                if (e.key === 'Enter') moveStep();
                            }}
                    />
                </FormGroup>
            </div>
        {/if}

        {#if currentStep === steps.contact}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Your contact details</h4>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="email"
                            id="appFormEmail"
                            placeholder="Email address"
                            bind:value={$appForm.email}
                            on:keydown={(e) => {
                                if (e.key === 'Enter') moveStep();
                            }}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="tel"
                            id="appFormPhone"
                            placeholder="Phone number"
                            bind:value={$appForm.phoneNumber}
                            on:keydown={(e) => {
                                if (e.key === 'Enter') moveStep();
                            }}
                    />
                </FormGroup>
            </div>
        {/if}

        {#if currentStep === steps.address}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Your address</h4>
                <LoqateAddressFinder bind:value={$appForm.address}/>
            </div>
        {/if}

        {#if currentStep === steps.finished}
            <div in:fly={{x: 250, duration: 350}}>
                <h2>{ toTitleCase($appForm.firstName)}, you selected...</h2>
                <Product product={$selectedProduct} />
                <p class="mt-3">If you are happy to proceed, click 'Submit'.</p>
                <Input type="checkbox"
                       id="appFormAgree"
                       bind:checked={$appForm.agree}
                       label="I agree to the terms and conditions"
                />
            </div>
        {/if}

        {#if isStepValid() === false && currentStepSubmit === true}
            <p class="text-danger">Complete all fields to continue</p>
        {/if}

        <div class="proceed__actions d-flex align-items-center justify-content-between mt-4 mb-3">
            {#if currentStep > steps.yourName}
                <Button size="lg" color="secondary" on:click={backStep}><Icon name="arrow-left-circle-fill" class="me-2" />Back</Button>
            {:else}
                <div/>
            {/if}
            {#if currentStep === steps.finished}
                <Button size="lg" color="success" on:click={moveStep}>Submit<Icon name="arrow-right-circle-fill" class="ms-2" /></Button>
            {:else}
                <Button size="lg" color="primary" on:click={moveStep}>Next<Icon name="arrow-right-circle-fill" class="ms-2" /></Button>
            {/if}
        </div>

    </Container>
</Container>

<Footer/>

<style>
</style>
