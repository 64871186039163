import {get, writable} from "svelte/store";
import portalApi from "./portalApi";
import {portalList} from "./portalList";

export const defaultPortal =  {
  standard: {
    brand: {
      name: "Believe",
      shortName: "Believe",
      logo: "believe.png"
    }
  },
  all: []
}

const storedPortal = JSON.parse(sessionStorage.getItem("portal"));

export const activePortal = writable(storedPortal || defaultPortal.standard);
export const _portals = writable(defaultPortal);

export const isLoan = writable(false);

const portalPromise = portalApi.portals();

export const portals = async () => {
  if (get(_portals).all.length === 0) {
    await setPortals();
  }
  return get(_portals);
}

async function setPortals() {
  let p = await portalPromise;
  let s = {
    ...defaultPortal,
    all: p
  }
  _portals.set(s);
}

export function setActivePortal(portalApi) {
  let p = portalList.all.find(x => x.api === portalApi) ?? portalList.standard;
  activePortal.set(p);
  if (p.api) {
    isLoan.set(p.api === "second-charge");
  } else {
    isLoan.set(false);
  }
  sessionStorage.setItem("portal", JSON.stringify(p));
}
