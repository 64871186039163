import "whatwg-fetch";
import App from './App.svelte';
import tracking from "./tracking";

const app = new App({
	target: document.body
});

tracking();

export default app;
