<script>
    import {Container} from "sveltestrap";
</script>

<Container class="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
    <h1>Page Not Found</h1>
    <p>Sorry, we couldn't find the page you were looking for.</p>
    <p>
        Please visit our <a href="/">home page</a>.
    </p>
</Container>

<style>
</style>