export default [
  {code: "GBR", country: "United Kingdom"},
  {code: "ABW", country: "Aruba"},
  {code: "AFG", country: "Afghanistan"},
  {code: "AGO", country: "Angola"},
  {code: "AIA", country: "Anguilla"},
  {code: "ALA", country: "Åland Islands"},
  {code: "ALB", country: "Albania"},
  {code: "AND", country: "Andorra"},
  {code: "ARE", country: "United Arab Emirates"},
  {code: "ARG", country: "Argentina"},
  {code: "ARM", country: "Armenia"},
  {code: "ASM", country: "American Samoa"},
  {code: "ATA", country: "Antarctica"},
  {code: "ATF", country: "French Southern Territories"},
  {code: "ATG", country: "Antigua and Barbuda"},
  {code: "AUS", country: "Australia"},
  {code: "AUT", country: "Austria"},
  {code: "AZE", country: "Azerbaijan"},
  {code: "BDI", country: "Burundi"},
  {code: "BEL", country: "Belgium"},
  {code: "BEN", country: "Benin"},
  {code: "BES", country: "Bonaire, Sint Eustatius and Saba"},
  {code: "BFA", country: "Burkina Faso"},
  {code: "BGD", country: "Bangladesh"},
  {code: "BGR", country: "Bulgaria"},
  {code: "BHR", country: "Bahrain"},
  {code: "BHS", country: "Bahamas"},
  {code: "BIH", country: "Bosnia and Herzegovina"},
  {code: "BLM", country: "Saint Barthélemy"},
  {code: "BLR", country: "Belarus"},
  {code: "BLZ", country: "Belize"},
  {code: "BMU", country: "Bermuda"},
  {code: "BOL", country: "Bolivia"},
  {code: "BRA", country: "Brazil"},
  {code: "BRB", country: "Barbados"},
  {code: "BRN", country: "Brunei Darussalam"},
  {code: "BTN", country: "Bhutan"},
  {code: "BVT", country: "Bouvet Island"},
  {code: "BWA", country: "Botswana"},
  {code: "CAF", country: "Central African Republic"},
  {code: "CAN", country: "Canada"},
  {code: "CCK", country: "Cocos (Keeling) Islands"},
  {code: "CHE", country: "Switzerland"},
  {code: "CHL", country: "Chile"},
  {code: "CHN", country: "China"},
  {code: "CIV", country: "Côte d'Ivoire"},
  {code: "CMR", country: "Cameroon"},
  {code: "COD", country: "DR Congo"},
  {code: "COG", country: "Congo"},
  {code: "COK", country: "Cook Islands"},
  {code: "COL", country: "Colombia"},
  {code: "COM", country: "Comoros"},
  {code: "CPV", country: "Cabo Verde"},
  {code: "CRI", country: "Costa Rica"},
  {code: "CUB", country: "Cuba"},
  {code: "CUW", country: "Curaçao"},
  {code: "CXR", country: "Christmas Island"},
  {code: "CYM", country: "Cayman Islands"},
  {code: "CYP", country: "Cyprus"},
  {code: "CZE", country: "Czechia"},
  {code: "DEU", country: "Germany"},
  {code: "DJI", country: "Djibouti"},
  {code: "DMA", country: "Dominica"},
  {code: "DNK", country: "Denmark"},
  {code: "DOM", country: "Dominican Republic"},
  {code: "DZA", country: "Algeria"},
  {code: "ECU", country: "Ecuador"},
  {code: "EGY", country: "Egypt"},
  {code: "ERI", country: "Eritrea"},
  {code: "ESH", country: "Western Sahara"},
  {code: "ESP", country: "Spain"},
  {code: "EST", country: "Estonia"},
  {code: "ETH", country: "Ethiopia"},
  {code: "FIN", country: "Finland"},
  {code: "FJI", country: "Fiji"},
  {code: "FLK", country: "Falkland Islands"},
  {code: "FRA", country: "France"},
  {code: "FRO", country: "Faroe Islands"},
  {code: "FSM", country: "Micronesia"},
  {code: "GAB", country: "Gabon"},
  {code: "GEO", country: "Georgia"},
  {code: "GGY", country: "Guernsey"},
  {code: "GHA", country: "Ghana"},
  {code: "GIB", country: "Gibraltar"},
  {code: "GIN", country: "Guinea"},
  {code: "GLP", country: "Guadeloupe"},
  {code: "GMB", country: "Gambia"},
  {code: "GNB", country: "Guinea-Bissau"},
  {code: "GNQ", country: "Equatorial Guinea"},
  {code: "GRC", country: "Greece"},
  {code: "GRD", country: "Grenada"},
  {code: "GRL", country: "Greenland"},
  {code: "GTM", country: "Guatemala"},
  {code: "GUF", country: "French Guiana"},
  {code: "GUM", country: "Guam"},
  {code: "GUY", country: "Guyana"},
  {code: "HKG", country: "Hong Kong"},
  {code: "HMD", country: "Heard Island and McDonald Islands"},
  {code: "HND", country: "Honduras"},
  {code: "HRV", country: "Croatia"},
  {code: "HTI", country: "Haiti"},
  {code: "HUN", country: "Hungary"},
  {code: "IDN", country: "Indonesia"},
  {code: "IMN", country: "Isle of Man"},
  {code: "IND", country: "India"},
  {code: "IOT", country: "British Indian Ocean Territory"},
  {code: "IRL", country: "Ireland"},
  {code: "IRN", country: "Iran"},
  {code: "IRQ", country: "Iraq"},
  {code: "ISL", country: "Iceland"},
  {code: "ISR", country: "Israel"},
  {code: "ITA", country: "Italy"},
  {code: "JAM", country: "Jamaica"},
  {code: "JEY", country: "Jersey"},
  {code: "JOR", country: "Jordan"},
  {code: "JPN", country: "Japan"},
  {code: "KAZ", country: "Kazakhstan"},
  {code: "KEN", country: "Kenya"},
  {code: "KGZ", country: "Kyrgyzstan"},
  {code: "KHM", country: "Cambodia"},
  {code: "KIR", country: "Kiribati"},
  {code: "KNA", country: "Saint Kitts and Nevis"},
  {code: "KOR", country: "South Korea"},
  {code: "KWT", country: "Kuwait"},
  {code: "LAO", country: "Lao People's Democratic Republic"},
  {code: "LBN", country: "Lebanon"},
  {code: "LBR", country: "Liberia"},
  {code: "LBY", country: "Libya"},
  {code: "LCA", country: "Saint Lucia"},
  {code: "LIE", country: "Liechtenstein"},
  {code: "LKA", country: "Sri Lanka"},
  {code: "LSO", country: "Lesotho"},
  {code: "LTU", country: "Lithuania"},
  {code: "LUX", country: "Luxembourg"},
  {code: "LVA", country: "Latvia"},
  {code: "MAC", country: "Macao"},
  {code: "MAF", country: "Saint Martin"},
  {code: "MAR", country: "Morocco"},
  {code: "MCO", country: "Monaco"},
  {code: "MDA", country: "Moldova"},
  {code: "MDG", country: "Madagascar"},
  {code: "MDV", country: "Maldives"},
  {code: "MEX", country: "Mexico"},
  {code: "MHL", country: "Marshall Islands"},
  {code: "MKD", country: "North Macedonia"},
  {code: "MLI", country: "Mali"},
  {code: "MLT", country: "Malta"},
  {code: "MMR", country: "Myanmar"},
  {code: "MNE", country: "Montenegro"},
  {code: "MNG", country: "Mongolia"},
  {code: "MNP", country: "Northern Mariana Islands"},
  {code: "MOZ", country: "Mozambique"},
  {code: "MRT", country: "Mauritania"},
  {code: "MSR", country: "Montserrat"},
  {code: "MTQ", country: "Martinique"},
  {code: "MUS", country: "Mauritius"},
  {code: "MWI", country: "Malawi"},
  {code: "MYS", country: "Malaysia"},
  {code: "MYT", country: "Mayotte"},
  {code: "NAM", country: "Namibia"},
  {code: "NCL", country: "New Caledonia"},
  {code: "NER", country: "Niger"},
  {code: "NFK", country: "Norfolk Island"},
  {code: "NGA", country: "Nigeria"},
  {code: "NIC", country: "Nicaragua"},
  {code: "NIU", country: "Niue"},
  {code: "NLD", country: "Netherlands"},
  {code: "NOR", country: "Norway"},
  {code: "NPL", country: "Nepal"},
  {code: "NRU", country: "Nauru"},
  {code: "NZL", country: "New Zealand"},
  {code: "OMN", country: "Oman"},
  {code: "PAK", country: "Pakistan"},
  {code: "PAN", country: "Panama"},
  {code: "PCN", country: "Pitcairn"},
  {code: "PER", country: "Peru"},
  {code: "PHL", country: "Philippines"},
  {code: "PLW", country: "Palau"},
  {code: "PNG", country: "Papua New Guinea"},
  {code: "POL", country: "Poland"},
  {code: "PRI", country: "Puerto Rico"},
  {code: "PRK", country: "North Korea"},
  {code: "PRT", country: "Portugal"},
  {code: "PRY", country: "Paraguay"},
  {code: "PSE", country: "Palestine"},
  {code: "PYF", country: "French Polynesia"},
  {code: "QAT", country: "Qatar"},
  {code: "REU", country: "Réunion"},
  {code: "ROU", country: "Romania"},
  {code: "RUS", country: "Russian Federation"},
  {code: "RWA", country: "Rwanda"},
  {code: "SAU", country: "Saudi Arabia"},
  {code: "SDN", country: "Sudan"},
  {code: "SEN", country: "Senegal"},
  {code: "SGP", country: "Singapore"},
  {code: "SGS", country: "South Georgia and the South Sandwich Islands"},
  {code: "SHN", country: "Saint Helena, Ascension and Tristan da Cunha"},
  {code: "SJM", country: "Svalbard and Jan Mayen"},
  {code: "SLB", country: "Solomon Islands"},
  {code: "SLE", country: "Sierra Leone"},
  {code: "SLV", country: "El Salvador"},
  {code: "SMR", country: "San Marino"},
  {code: "SOM", country: "Somalia"},
  {code: "SPM", country: "Saint Pierre and Miquelon"},
  {code: "SRB", country: "Serbia"},
  {code: "SSD", country: "South Sudan"},
  {code: "STP", country: "Sao Tome and Principe"},
  {code: "SUR", country: "Suriname"},
  {code: "SVK", country: "Slovakia"},
  {code: "SVN", country: "Slovenia"},
  {code: "SWE", country: "Sweden"},
  {code: "SWZ", country: "Eswatini"},
  {code: "SXM", country: "Sint Maarten"},
  {code: "SYC", country: "Seychelles"},
  {code: "SYR", country: "Syrian Arab Republic"},
  {code: "TCA", country: "Turks and Caicos Islands"},
  {code: "TCD", country: "Chad"},
  {code: "TGO", country: "Togo"},
  {code: "THA", country: "Thailand"},
  {code: "TJK", country: "Tajikistan"},
  {code: "TKL", country: "Tokelau"},
  {code: "TKM", country: "Turkmenistan"},
  {code: "TLS", country: "Timor-Leste"},
  {code: "TON", country: "Tonga"},
  {code: "TTO", country: "Trinidad and Tobago"},
  {code: "TUN", country: "Tunisia"},
  {code: "TUR", country: "Turkey"},
  {code: "TUV", country: "Tuvalu"},
  {code: "TWN", country: "Taiwan"},
  {code: "TZA", country: "Tanzania"},
  {code: "UGA", country: "Uganda"},
  {code: "UKR", country: "Ukraine"},
  {code: "UMI", country: "United States Minor Outlying Islands"},
  {code: "URY", country: "Uruguay"},
  {code: "USA", country: "United States of America"},
  {code: "UZB", country: "Uzbekistan"},
  {code: "VAT", country: "Holy See"},
  {code: "VCT", country: "Saint Vincent and the Grenadines"},
  {code: "VEN", country: "Venezuela"},
  {code: "VGB", country: "Virgin Islands (British)"},
  {code: "VIR", country: "Virgin Islands (US)"},
  {code: "VNM", country: "Viet Nam"},
  {code: "VUT", country: "Vanuatu"},
  {code: "WLF", country: "Wallis and Futuna"},
  {code: "WSM", country: "Samoa"},
  {code: "YEM", country: "Yemen"},
  {code: "ZAF", country: "South Africa"},
  {code: "ZMB", country: "Zambia"},
  {code: "ZWE", country: "Zimbabwe"}
];
