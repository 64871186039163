<script>
    import { fly } from 'svelte/transition';

    import {Button, Container, FormGroup, Icon, Input, InputGroup, InputGroupText} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";

    import { activePortal, isLoan } from "../../Shared/Data/Portal/portalStore";
    import {sourcingParameters, source, gotoLocation} from "../../Shared/Data/Tool/toolStore";
    import mortgageLenders from "../../Shared/Data/Lists/mortgageLenders";
    import employmentStatus from "../../Shared/Data/Lists/employmentStatus";
    import {isNumber} from "luxon/src/impl/util";
    import {onMount} from "svelte";

    const steps = {
        lookingFor: 0,
        propertyValue: 1,
        mortgageOutstanding: 2,
        mortgageTermRemaining: 3,
        mortgageFixed: 4,
        mortgageFixedTermRemaining: 5,
        mortgageLender: 6,
        additionalBorrowing: 7,
        raiseAmount: 8,
        raiseTerm: 9,
        employmentStatus: 10,
        grossAnnualIncome: 11,
        results: 12
    }

    let currentStep = -1;
    let currentStepSubmit = false;

    onMount(() => {
        resetStep();
    })

    function resetStep() {
        $sourcingParameters.lookingFor = ($isLoan ? "loan" : null);
        currentStep = $isLoan ? steps.propertyValue : steps.lookingFor;
    }

    $: isStepValid = () => {
        switch (currentStep) {
            case steps.lookingFor:
                return $sourcingParameters.lookingFor !== null;
            case steps.propertyValue:
                return $sourcingParameters.propertyValue !== null && isNumber($sourcingParameters.propertyValue);
            case steps.mortgageOutstanding:
                return $sourcingParameters.mortgageOutstanding !== null && isNumber($sourcingParameters.mortgageOutstanding);
            case steps.mortgageTermRemaining:
                return $sourcingParameters.mortgageTermRemaining.years !== null && isNumber($sourcingParameters.mortgageTermRemaining.years)
                    && $sourcingParameters.mortgageTermRemaining.months !== null && isNumber($sourcingParameters.mortgageTermRemaining.months);
            case steps.mortgageFixed:
                return $sourcingParameters.mortgageFixed !== null;
            case steps.mortgageFixedTermRemaining:
                return $sourcingParameters.mortgageFixedTermRemaining.years !== null && isNumber($sourcingParameters.mortgageFixedTermRemaining.years)
                    && $sourcingParameters.mortgageFixedTermRemaining.months !== null && isNumber($sourcingParameters.mortgageFixedTermRemaining.months);
            case steps.additionalBorrowing:
                return $sourcingParameters.raise !== null;
            case steps.raiseAmount:
                return $sourcingParameters.raiseAmount !== null && isNumber($sourcingParameters.raiseAmount);
            case steps.raiseTerm:
                return $sourcingParameters.mortgageTermRemaining.years !== null && isNumber($sourcingParameters.mortgageTermRemaining.years)
                    && $sourcingParameters.mortgageTermRemaining.months !== null && isNumber($sourcingParameters.mortgageTermRemaining.months);
            case steps.employmentStatus:
                return $sourcingParameters.employmentStatus !== null;
            case steps.grossAnnualIncome:
                return $sourcingParameters.grossAnnualIncome !== null && isNumber($sourcingParameters.grossAnnualIncome);
        }
    }

    function moveStep() {

        let nextStep = null;

        // set as submit attempt
        currentStepSubmit = true;

        // Validate
        if (isStepValid() === false) return;

        // Reset
        currentStepSubmit = false;

        // If purchase skip mortgage questions and goto borrowing amount
        if (currentStep === steps.propertyValue && $sourcingParameters.lookingFor === "purchase") {
            nextStep = steps.raiseAmount;
        }
        // If mortgage outstanding and a loan then goto raise amount
        if (currentStep === steps.mortgageOutstanding && $sourcingParameters.lookingFor === "loan") {
            nextStep = steps.raiseAmount;
        }
        // If not fixed skip
        if (currentStep === steps.mortgageFixed && $sourcingParameters.mortgageFixed === false) {
            nextStep = steps.mortgageLender;
        }
        // If no additional borrowing skip
        if (currentStep === steps.additionalBorrowing && $sourcingParameters.raise === false) {
            nextStep = steps.employmentStatus;
        }
        // If no additional borrowing skip
        if (currentStep === steps.raiseAmount && $sourcingParameters.lookingFor === "remortgage") {
            nextStep = steps.employmentStatus;
        }
        // if last step then goto results
        if (currentStep === steps.results - 1) {
            source();
            gotoLocation('quotes');
            return;
        }

        if (nextStep) {
            currentStep = nextStep;
        } else {
            currentStep++;
        }

        // find input and focus
        setTimeout(() => {
            let els = document.querySelectorAll('input,select');
            if (els != null && els.length > 0) els[0].focus();
        }, 100);

    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Start your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

<Container fluid class="get-started d-flex position-relative">
    <Container class="py-2">
        {#if currentStep !== steps.results}
            <h2 class="my-3 mb-4">Let's get started...</h2>
        {:else}
            <h2 class="my-3 mb-4">That's everything!</h2>
        {/if}

        {#if currentStep === steps.lookingFor}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Looking to remortgage or purchase?</h4>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="radio"
                            id="purchase"
                            value="purchase"
                            label="Purchase"
                            bind:group={$sourcingParameters.lookingFor}
                    />
                    <Input
                            bsSize="lg"
                            type="radio"
                            id="remortgage"
                            value="remortgage"
                            label="Remortgage"
                            bind:group={$sourcingParameters.lookingFor}
                    />
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.propertyValue}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>How much is the property worth?</h4>
                <FormGroup>
                    <InputGroup>
                        <InputGroupText>£</InputGroupText>
                        <Input
                                bsSize="lg"
                                type="number"
                                id="propertyValue"
                                bind:value={$sourcingParameters.propertyValue}
                        />
                    </InputGroup>
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.mortgageOutstanding}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>How much is left to pay on your mortgage?</h4>
                <FormGroup>
                    <InputGroup>
                        <InputGroupText>£</InputGroupText>
                        <Input
                                bsSize="lg"
                                type="number"
                                id="mortgageOutstanding"
                                bind:value={$sourcingParameters.mortgageOutstanding}
                        />
                    </InputGroup>
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.mortgageTermRemaining}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>How long is left to pay on your mortgage?</h4>
                <FormGroup>
                    <InputGroup>
                        <Input
                                bsSize="lg"
                                type="select"
                                id="mortgageTermRemainingYears"
                                bind:value={$sourcingParameters.mortgageTermRemaining.years}
                        >
                            <option value={null} disabled>Years</option>
                            {#each Array.from({length: 41}, (v, i) => i) as year}
                                {#if year >= 5}
                                    <option value={year}>{year} year{year > 1 ? 's' : ''}</option>
                                {/if}
                            {/each}
                        </Input>
                        <Input
                                bsSize="lg"
                                type="select"
                                id="mortgageTermRemainingMonths"
                                bind:value={$sourcingParameters.mortgageTermRemaining.months}
                        >
                            <option value={null} disabled>Months</option>
                            {#each Array.from({length: 11}, (v, i) => i) as month}
                                <option value={month}>{month} month{month > 1 ? 's' : ''}</option>
                            {/each}
                        </Input>
                    </InputGroup>
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.mortgageFixed}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Is your mortgage on a fixed rate?</h4>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="radio"
                            id="mortgageFixed"
                            value={true}
                            label="Yes"
                            bind:group={$sourcingParameters.mortgageFixed}
                    />
                    <Input
                            bsSize="lg"
                            type="radio"
                            id="mortgageNotFixed"
                            value={false}
                            label="No"
                            bind:group={$sourcingParameters.mortgageFixed}
                    />
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.mortgageFixedTermRemaining}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>When does your current fixed rate end?</h4>
                <FormGroup>
                    <InputGroup>
                        <Input
                                bsSize="lg"
                                type="select"
                                id="mortgageFixedTermRemainingYears"
                                bind:value={$sourcingParameters.mortgageFixedTermRemaining.years}
                        >
                            <option value={null} disabled>Years</option>
                            {#each Array.from({length: 41}, (v, i) => i) as year}
                                <option value={year}>{year} year{year > 1 ? 's' : ''}</option>
                            {/each}
                        </Input>
                        <Input
                                bsSize="lg"
                                type="select"
                                id="mortgageFixedTermRemainingMonths"
                                bind:value={$sourcingParameters.mortgageFixedTermRemaining.months}
                        >
                            <option value={null} disabled>Months</option>
                            {#each Array.from({length: 11}, (v, i) => i) as month}
                                <option value={month}>{month} month{month > 1 ? 's' : ''}</option>
                            {/each}
                        </Input>
                    </InputGroup>
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.mortgageLender}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Who is your current mortgage with?</h4>
                <FormGroup>
                    <Input
                            bsSize="lg"
                            type="select"
                            id="mortgageLender"
                            bind:value={$sourcingParameters.mortgageLender}
                    >
                        {#each mortgageLenders as lender}
                            <option value={lender.value}>{lender.label}</option>
                        {/each}
                    </Input>
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.additionalBorrowing}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Do you want any additional borrowing?</h4>
                <FormGroup>
                    <Input
                            class="form-check-inline"
                            bsSize="lg"
                            type="radio"
                            id="raiseYes"
                            value={true}
                            label="Yes"
                            bind:group={$sourcingParameters.raise}
                    />
                    <Input
                            class="form-check-inline"
                            bsSize="lg"
                            type="radio"
                            id="raiseNo"
                            value={false}
                            label="No"
                            bind:group={$sourcingParameters.raise}
                    />
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.raiseAmount}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>How much do you want to borrow?</h4>
                <FormGroup>
                    <InputGroup>
                        <InputGroupText>£</InputGroupText>
                        <Input
                                bsSize="lg"
                                type="number"
                                id="raiseAmount"
                                bind:value={$sourcingParameters.raiseAmount}
                        />
                    </InputGroup>
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.raiseTerm}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>Over what period would you like this to be over?</h4>
                <FormGroup>
                    <InputGroup>
                        <Input
                                bsSize="lg"
                                type="select"
                                id="raiseTermYears"
                                bind:value={$sourcingParameters.mortgageTermRemaining.years}
                        >
                            <option value={null} disabled>Years</option>
                            {#each Array.from({length: 41}, (v, i) => i) as year}
                                {#if $isLoan || year >= 5}
                                    <option value={year}>{year} year{year > 1 ? 's' : ''}</option>
                                {/if}
                            {/each}
                        </Input>
                        <Input
                                bsSize="lg"
                                type="select"
                                id="raiseTermMonths"
                                bind:value={$sourcingParameters.mortgageTermRemaining.months}
                        >
                            <option value={null} disabled>Months</option>
                            {#each Array.from({length: 11}, (v, i) => i) as month}
                                <option value={month}>{month} month{month > 1 ? 's' : ''}</option>
                            {/each}
                        </Input>
                    </InputGroup>
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.employmentStatus}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>What is your employment status?</h4>
                <FormGroup class="d-">
                    {#each employmentStatus as status}
                        <Input
                                bsSize="lg"
                                type="radio"
                                id={status.value}
                                value={status.value}
                                label={status.label}
                                bind:group={$sourcingParameters.employmentStatus}
                        />
                    {/each}
                </FormGroup>
            </div>
        {/if}
        {#if currentStep === steps.grossAnnualIncome}
            <div in:fly={{x: 250, duration: 350}}>
                <h4>What is your gross annual income?</h4>
                <FormGroup>
                    <InputGroup>
                        <Input
                                bsSize="lg"
                                type="number"
                                id="grossAnnualIncome"
                                bind:value={$sourcingParameters.grossAnnualIncome}
                        />
                    </InputGroup>
                </FormGroup>
            </div>
        {/if}
        {#if isStepValid() === false && currentStepSubmit === true}
            <p class="text-danger">Complete all fields to continue</p>
        {/if}
        <div class="get-started__actions d-flex align-items-center justify-content-between mt-4 mb-3">
            {#if currentStep > steps.lookingFor}
                <Button size="lg" color="secondary" on:click={resetStep}><Icon name="skip-start-fill" class="me-2" />Start Again</Button>
            {:else}
                <div/>
            {/if}
            {#if currentStep === steps.results - 1}
                <Button size="lg" color="success" on:click={moveStep}>Get my quote<Icon name="arrow-right-circle-fill" class="ms-2" /></Button>
            {:else}
                <Button size="lg" color="primary" on:click={moveStep}>Next<Icon name="arrow-right-circle-fill" class="ms-2" /></Button>
            {/if}
        </div>
    </Container>
</Container>

<Footer/>

<style>
</style>
