<script>

    import {writable} from "svelte/store";
    import {createEventDispatcher} from "svelte";

    const dispatch = createEventDispatcher();

    export const calldate = writable(null);
    export const calltime = writable(null);

    import {callbackDays, callbackTimes} from "../../Data/Utils/callback";
    import {watch} from "../../Data/Utils/utils";
    import {onMount} from "svelte";
    import {Button, Col, FormGroup, Icon, Input, Row} from "sveltestrap";
    import {setCallback} from "../../Data/Tool/toolStore";
    watch(calldate, ($calldate) => {
        let times = callbackTimes($calldate);
        if (times.length) calltime.set(times[0].value);
    });

    onMount(() => {
        let days = callbackDays();
        calldate.set(days[0].value);
    });

    $: isValid = $calldate && $calltime && $calldate.length && $calltime.length;

    async function submit() {
        if (isValid) {
            let data = {
                date: $calldate,
                time: $calltime
            };
            await setCallback(data);
            dispatch("submitted");
        }
    }


</script>

<h4 class="mt-3">Call me on...</h4>
<FormGroup>
    {#each callbackDays() as {value, label}}
        <Input
                bsSize="lg"
                type="radio"
                id={value}
                value={value}
                label={label}
                bind:group={$calldate}
        />
    {/each}
</FormGroup>
<h4 class="mb-3">What time is best?</h4>
<Row>
    {#each callbackTimes($calldate) as {value, label}}
        <Col xs="6" sm="4" md="3" lg="2" xl="2" class="mb-2">
            <input type="radio" class="btn-check" id={value} name="time" autocomplete="off" bind:group={$calltime} value={value}>
            <label class="btn btn-lg btn-outline-primary w-100" for={value}>{label}</label>
        </Col>
    {/each}
</Row>
{#if isValid === false}
    <p class="text-danger">Complete all fields to continue</p>
{/if}
<div class="d-flex align-items-center justify-content-between mt-4 mb-3">
    <Button size="lg" color="success" on:click={submit}><Icon class="me-2" name="telephone-inbound"/>Request Call</Button>
</div>