<script>
    import Product from "./Product.svelte";
    import {Button, Icon} from "sveltestrap";
    import {createEventDispatcher} from "svelte";
    import Pager from "./Pager.svelte";

    // Dispatcher
    const dispatch = createEventDispatcher();

    export let results = []
    export let pageData = {}

    $: pageDataItems = results.slice(pageData.start, pageData.end);

    function selectedProduct(product) {
        dispatch('selectedProduct', product)
    }

</script>

<div class="results d-flex flex-column">
    {#if results.length === 0}
        <div class="w-100 p-3 shadow text-center">
            <h3 class="text-center mt-3">No results found</h3>
            <p class="text-muted">Try adjusting the filters to show more products.</p>
        </div>
    {:else}
        <Pager pageData={pageData} on:pageChanged on:perPageChanged />
        {#each pageDataItems as product}
            <Product {product}>
                <Button
                        size="lg"
                        color="success"
                        class="shadow ms-auto me-auto me-md-0 mb-2"
                        on:click={() => {
                        selectedProduct(product)
                        }
                    }>
                    Continue<Icon name="arrow-right-circle-fill" class="ms-2" />
                </Button>
            </Product>
        {/each}
        <Pager pageData={pageData} on:pageChanged on:perPageChanged />
    {/if}
</div>

<style>
    .results {
        gap:1rem;
    }
</style>