<script>
    import {Container} from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";
    import {activePortal} from "../../Shared/Data/Portal/portalStore";
    import {appFormResult, clearSession} from "../../Shared/Data/Tool/toolStore";
    import Callback from "../../Shared/Components/Callback/Callback.svelte";

    $: canCallback = $appFormResult && $appFormResult.journey && $appFormResult.journey.callbackTime === null;

    function submitted() {
        clearSession();
    }

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Thank You`}</title>
</svelte:head>

<Container class="px-3 py-3">
    <h1>Thank you</h1>
    <p>We have received your details and selected product and will be in touch soon.</p>
    {#if canCallback}
        <h3>Schedule Callback</h3>
        <p>If you would like to schedule a call back, let us know when and we will call you at a time that is good for you.</p>
        <Callback on:submitted={submitted} />
    {:else}
        <p class="muted">You may close this window.</p>
    {/if}
</Container>

<Footer/>

<style>
</style>
