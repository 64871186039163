<script>

</script>

<h5>Sort By</h5>
<p>Choose how you would like to sort the products:</p>
<ul>
    <li><strong>True Cost</strong> - Sort by true cost to see which deal will cost you the least with all factors considered.</li>
    <li><strong>Initial Rate</strong> – Sort by initial rate to see which deal has the lowest interest your borrowing will start on.</li>
    <li><strong>Initial Repayment</strong> – Sort by initial repayment to see which deal has the lowest payment your borrowing will start on.</li>
    <li><strong>APRC</strong> –  Sort by as a percentage, the annual cost of the borrowing over its lifetime.</li>
    <li><strong>Total Fees</strong> – Sort by which deal will charge you the least in fees to take out the borrowing.</li>
</ul>

<h5>Would you like to look at a fixed rate or variable rate?</h5>
<p>Choose if you would like to take a variable product, or a fixed rate product & over what fixed term:</p>
<ul>
    <li><strong>Fixed Rate</strong> – Offers you a fixed interest percentage to pay back over an agreed period of the loan. This helps you calculate your repayments as the fixed rate is unaffected by market increases. However, the repayments will also not go down should the market rate decrease. A fixed rate helps take away the risk of not being able to pay an unexpected higher monthly repayment, but also removes the possibility of benefiting from lower monthly payments should the market rate go down.</li>
    <li><strong>Variable Rate</strong> – Allows the lender to increase or decrease the interest rate at any point during a credit agreement, normally but not always as a result of fluctuations in the market base rate. On a variable rate you risk being less able to afford the repayments if the interest rates rise, but you are able to benefit from more affordable repayments if rates fall.</li>
    <li><strong>Tracker Rate</strong> – Tracker mortgages "follow" the Bank of England’s base rate. If interest rates fall, you’ll make lower payments to your lender. If interest rates rise, your payments will increase.</li>
</ul>

<h5>How long would you like to fix the rate for?</h5>
<p>Choose how long you would like to fix your mortgage for, the longer you fix the rate for, the longer you will have the same payment for. If you wish to exit a fixed term early, you may be subject to lender early repayment charges.</p>