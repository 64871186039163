<script>

    import {onMount} from "svelte";

    import {
        Button,
        Col,
        Container,
        Icon, Input,
        Offcanvas,
        OffcanvasBody,
        Row
    } from "sveltestrap";
    import Footer from "../../Shared/Layout/Footer.svelte";

    import {activePortal} from "../../Shared/Data/Portal/portalStore";
    import {
        sourcingResults, filters, updateSelectedProduct, appFormResult, gotoLocation
    } from "../../Shared/Data/Tool/toolStore";
    import Results from "../../Shared/Components/Results/Results.svelte";
    import Filters from "../../Shared/Components/Results/Filters.svelte";
    import {fixedRates} from "../../Shared/Data/Lists/productTypes";
    import sortBy from "../../Shared/Data/Lists/sortBy";
    import Info from "../../Shared/Components/Info/Info.svelte";
    import {watch} from "../../Shared/Data/Utils/utils";

    onMount(async () => {
        if ($sourcingResults) {
            let consolidation = $sourcingResults.find(x => x.quoteType === "consolidationExample");
            if (consolidation) {
                if ((consolidation.products ?? []).length > 0) {
                    return;
                }
            }
        }
        gotoLocation('proceed');
    });

    //
    // Product results, filtered and sorted based on selection
    //
    $: consolidation = $sourcingResults ? $sourcingResults.find(x => x.quoteType === "consolidationExample") : null;
    $: products = consolidation && consolidation.products ? consolidation.products : [];
    $: initialQuoteResults = products
        .filter(x => {
            if ($filters.rateType === "all") {
                return true;
            } else if ($filters.rateType === 'fixed') {
                return x.rates[0].type === 'fixed';
            } else if ($filters.rateType === 'variable') {
                return x.rates[0].type === 'variable';
            } else if ($filters.rateType === 'tracker') {
                return x.rates[0].type === 'tracker';
            }
            return false;
        })
        .filter(x => {
            if ($filters.rateType === 'fixed' || $filters.rateType === 'tracker') {
                if ($filters.fixedType !== "all") {
                    let rate = fixedRates.find(x => x.value === $filters.fixedType);
                    if (rate != null) {
                        let rateTerm = x.rates[0].term;
                        return (rateTerm >= rate.minTerm && rateTerm <= rate.maxTerm);
                    }
                }
            }
            return true;
        })
        .sort((a, b) => {
            if ($filters.sortBy === 'initialRate') {
                return Number(a.rates[0].rate) - Number(b.rates[0].rate);
            } else if ($filters.sortBy === 'initialRepayment') {
                return Number(a.rates[0].monthlyRepayment) - Number(b.rates[0].monthlyRepayment);
            } else if ($filters.sortBy === 'aprc') {
                return Number(a.aprc) - Number(b.aprc);
            } else if ($filters.sortBy === 'totalFees') {
                let feeA = Number(a.fees.reduce((x, y) => x + y.amount, 0)) || 0;
                let feeB = Number(b.fees.reduce((x, y) => x + y.amount, 0)) || 0;
                return feeA - feeB;
            } else if ($filters.sortBy === 'totalRepayable') {
                let totalRepayableA = a.rates.length ? a.rates.reduce((a, b) => a + (b.monthlyRepayment * b.term), 0) : 0;
                let totalRepayableB = b.rates.length ? b.rates.reduce((a, b) => a + (b.monthlyRepayment * b.term), 0) : 0;
                return totalRepayableA - totalRepayableB;
            } else {
                return Number(a.trueCost) - Number(b.trueCost);
            }
        });

    //
    // Pager
    //
    let pageIndex = 0;
    let itemsPerPage = 10;

    watch(filters, (updatedFilters) => {
        pageIndex = 0;
    });

    $: quotePageData = {
        page: pageIndex + 1,
        pages: Math.ceil(initialQuoteResults.length / itemsPerPage),
        total: initialQuoteResults.length,
        perPage: itemsPerPage,
        start: pageIndex * itemsPerPage,
        end: (pageIndex * itemsPerPage) + itemsPerPage
    };

    //
    // Select Product
    //
    function selectProduct(event) {
        updateSelectedProduct(event.detail);
        gotoNextStep();
    }

    function gotoNextStep() {
        gotoLocation('income');
    }

    //
    // Edit Overlay
    //
    let showUpdateParams = false;
    const toggleParams = () => (showUpdateParams = !showUpdateParams);

    //
    // Filter overlay
    //
    let showFilters = false;
    const toggleFilters = () => (showFilters = !showFilters);

    //
    // Info overlay
    //
    let showInfo = false;
    const toggleInfo = () => (showInfo = !showInfo);

</script>

<svelte:head>
    <title>{`${$activePortal.brand.name} - Your ${$activePortal.term.toLowerCase()} quote...`}</title>
</svelte:head>

<Container fluid class="consolidation position-relative">
    <div class="pt-3">
        <h4>Consolidate your debts?</h4>
        <p>We have found you have some debts based on your credit file. We could help reduce your monthly payments by consolidating them with your mortgage.</p>
        <p>Below you will find products that may been of interest to you and your situation, however if you are happy with the product you have already selected, just continue.</p>
        <Button color="primary" on:click={gotoNextStep}>Continue<Icon name="arrow-right-circle-fill" class="ms-2" /></Button>
    </div>
    <hr/>
    <div class="d-flex align-items-start justify-content-start w-100 h-100 pb-3">
        <Row class="flex-fill w-100">
            <Col xs="12" md="4" lg="3" class="mb-2 d-flex d-md-block align-items-center justify-content-between">
                <div class="d-none d-md-block">
                    <Filters on:info={toggleInfo} />
                </div>
            </Col>
            <Col xs="12" md="8" lg="9">
                <div class="d-flex align-items-center mb-3 d-md-none">
                    <Button class="me-1" color="primary" on:click={toggleFilters}><Icon name="filter" class="me-2" />Filter</Button>
                </div>
                <div class="shadow p-2 mb-2">
                    <div class="d-flex align-items-center">
                        <label for="sortBy">Sort By</label>
                        <Button color="link" class="p-0 ms-2" on:click={toggleInfo}>
                            <Icon name="info-circle-fill fs-5" />
                        </Button>
                    </div>
                        <Input
                                type="select"
                                name="sortBy"
                                id="sortBy"
                                bind:value={$filters.sortBy}
                        >
                            {#each sortBy as sort}
                                <option value={sort.value}>{sort.label}</option>
                            {/each}
                        </Input>
                </div>
                <Results
                        results={initialQuoteResults}
                        pageData={quotePageData}
                        on:pageChanged={e => pageIndex = e.detail}
                        on:perPageChanged={e => { pageIndex = 0; itemsPerPage = e.detail }}
                        on:selectedProduct={selectProduct}
                />
            </Col>
        </Row>

        <Offcanvas
                isOpen={showFilters}
                toggle={toggleFilters}
                header="Change Filters"
                placement="end"
                scroll
                body={false}
        >
            <OffcanvasBody class="py-0">
                <Filters
                        on:info={toggleInfo}
                        on:update={() => {showUpdateParams = false}}
                />
                <div class="bg-light p-2">
                    <Button
                            color="primary"
                            class="d-block ms-auto"
                            on:click={toggleFilters}
                    >Update</Button>
                </div>
            </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
                isOpen={showInfo}
                toggle={toggleInfo}
                header="Help"
                placement="end"
                scroll
                body={false}
        >
            <OffcanvasBody class="py-0">
                <Info/>
            </OffcanvasBody>
        </Offcanvas>
    </div>
</Container>

<Footer/>

<style>
</style>
