<script>
    import {createEventDispatcher} from "svelte";

    const dispatch = createEventDispatcher();

    export let eSignUrl = null;

    $: {
        if (eSignUrl) {
            dispatch("working");
        }
    }

    window.addEventListener("message", e => {
        //console.log("ESign Event", e.data, e);
        if (e.data === "event_loaded") {
            dispatch("loaded", e);
        } else if (e.data === "event_signed") {
            eSignUrl = null;
            dispatch("signed", e);
        } else if (e.data === "event_declined") {
            eSignUrl = null;
            dispatch("declined", e);
        } else if (e.data === "event_error") {
            eSignUrl = null;
            dispatch("error", e);
        } else if (e.data.error_message === "Cannot Sign Document") {
            eSignUrl = null;
            dispatch("error", e.data.error_message);
        }
    });

</script>

{#if eSignUrl}
    <div class="esign">
        <iframe title="E-Signatures" src={eSignUrl} frameborder="0"></iframe>
    </div>
{/if}

<style>
    div.esign {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 110;
    }

    div.esign > iframe {
        width: 100%;
        height: 100%;
    }
</style>