export default [
    {value: "all", label: "Show all"},
    {value: "fixed", label: "Fixed"},
    {value: "variable", label: "Variable"},
    {value: "tracker", label: "Tracker"},
]

export const fixedRates = [
    {value: "all", label: "Show all"},
    {minTerm: 12, maxTerm: 23, value: "fixed-12", label: "1 Year"},
    {minTerm: 24, maxTerm: 35, value: "fixed-24", label: "2 Years"},
    {minTerm: 36, maxTerm: 47, value: "fixed-36", label: "3 Years"},
    {minTerm: 48, maxTerm: 59, value: "fixed-48", label: "4 Years"},
    {minTerm: 60, maxTerm: 71, value: "fixed-60", label: "5 Years"},
    {minTerm: 72, maxTerm: 71, value: "fixed-72", label: "5 Years+"}
]
